
.acc-loans {
  &__hero {
    box-sizing: border-box;
    background: url('../images/loans/hero_bg.jpg') no-repeat #0268B2;
    background-size: 100% 100%;
    padding-top: 97px;
    padding-bottom: 268px;

    @include up-to-tablet2 {
      padding-bottom: 360px;
    }

    .acc-container {
      position: relative;
    }

    &__image {
      display: block;
      width: 277px;
      height: 277px;
      margin: 0 auto 77px auto;

      @include up-to-tablet2 {
        width: 195px;
        height: 195px;
        margin-bottom: 47px;
      }
    }

    &__header {
      font-size: 58px;
      line-height: 80px;
      text-align: center;
      letter-spacing: -3px;
      color: #FFFFFF;
      margin-bottom: 42px;

      @include up-to-tablet2 {
        font-size: 40px;
        line-height: 45px;
        letter-spacing: 0;
      }
    }

    &__text {
      color: #FFFFFF;
      font-style: normal;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      max-width: 1057px;
      margin: 0 auto;

      @include up-to-tablet2 {
        font-size: 16px;
        line-height: 30px;
        text-align: justify;
      }
    }

    &__buttons {
      margin-top: 42px;
      margin-bottom: 142px;
      text-align: center;

      @include up-to-tablet2 {
        margin-bottom: 75px;
      }

      button {
        margin: 0 20px;

        @include up-to-tablet2 {
          width: 226px;
        }
      }

      &__irq {
        width: 226px;
      }
    }

    &__form {
      position: absolute;
      left: 0;
      right: 0;
      height: 391px;
      box-sizing: border-box;
      background: #FFFFFF;
      box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      padding: 75px 130px;

      @include up-to-tablet2 {
        left: 25px;
        right: 25px;
        padding: 67px 40px;
        height: auto;
      }

      &__line {
        display: flex;
        margin-bottom: 32px;

        @include up-to-tablet2 {
          flex-wrap: wrap;
          margin-bottom: 0;
        }

        &__item {
          width: calc(50% - 17.5px);

          @include up-to-tablet2 {
            width: 100%;
          }

          &:not(:last-child) {
            margin-right: 35px;

            @include up-to-tablet2 {
              margin-right: 0;
            }
          }

          input {
            height: 64px;
            font-size: 18px;
            padding: 0 28px;

            @include up-to-tablet2 {
              height: 46px;
              font-size: 14px;
              padding: 0 24px;
              margin-bottom: 36px;
            }
          }
        }
      }

      &__buttons {
        padding-top: 19px;
        text-align: center;

        button {
          width: 150px;          
        }
      }
    }
  }

  &__main {
    padding-top: 254px;
    padding-bottom: 183px;

    @include up-to-tablet2 {
      padding-bottom: 132px;
    }

    &__header {
      font-size: 45px;
      line-height: 60px;
      text-align: center;
      color: #6699FF;
      max-width: 696px;
      margin: 0 auto;

      @include up-to-tablet2 {
        font-size: 24px;
        line-height: 35px;
      }      
    }

    &__blocks {
      display: flex;
      margin: 65px 0 130px 0;

      @include up-to-tablet2 {
        flex-wrap: wrap;
        margin-bottom: 112px;
      }

      &__item {
        display: block;
        text-decoration: none;
        width: calc(25% - 16.5px);
        height: 392px;
        box-sizing: border-box;
        padding: 29px 31px;
        background-color: #6699FF;
        border-radius: 16px;

        @include up-to-tablet2 {
          width: 100%;
          height: auto;
          margin-bottom: 40px;
        }

        &:not(:last-child) {
          margin-right: 22px;

          @include up-to-tablet2 {
            margin-right: 0;
          }
        }

        &__image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 92px;
          margin: 0 auto;
          border-radius: 45px;
          background-color: #FFFFFF;
          margin-bottom: 19px;
          text-align: center;

          img {
            width: 80px;
            height: 80px;
          }
        }

        &__header {
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #FFFFFF;
          margin-bottom: 19px;
          text-decoration: none;

          @include up-to-tablet2 {
            font-size: 18px;
          }
        }

        &__list {
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
          color: #FFFFFF;
          padding: 0;
          margin-left: 31px;

          @include up-to-tablet2 {
            font-size: 18px;
            margin-left: 14px;
            margin-bottom: 10px;
          }

          li {
            margin-bottom: 10px;
            text-decoration: none;
          }
        }
      }
    }

    &__area {
      background: #6699FF;
      border-radius: 16px;
      padding: 76px 100px;
      margin-bottom: 149px;

      @include up-to-tablet2 {
        margin-bottom: 101px;
        padding: 35px 28px 46px 28px;
      }

      &__header {
        font-size: 45px;
        line-height: 60px;
        text-align: center;
        color: #FFFFFF;
        max-width: 778px;
        margin: 0 auto 29px auto;

        @include up-to-tablet2 {
          font-size: 24px;
          line-height: 40px;          
        }
      }

      &__text {
        margin-bottom: 70px;
        font-size: 18px;
        line-height: 28px;
        text-align: justify;
        color: rgba(255, 255, 255, 0.7);

        @include up-to-tablet2 {
          margin-bottom: 26px;
          font-size: 14px;
          line-height: 20px;
        }
      }

      &__buttons {
        display: flex;
        justify-content: center;

        @include up-to-tablet2 {
          flex-wrap: wrap;
        }

        button {
          margin: 0 20px;
          width: 154px;
        }        
      }
    }

    &__contacts {
      &__header {
        margin: 0 auto 82px auto;
        font-size: 45px;
        line-height: 48px;
        text-align: center;
        letter-spacing: -1px;
        color: #6699FF;

        @include up-to-tablet2 {
          font-size: 24px;
          line-height: 48px;
          margin-bottom: 31px;
        }
      }

      &__box {
        display: flex;

        @include up-to-tablet2 {
          flex-wrap: wrap;
        }
      }

      &__map {
        width: 659px;
        height: 440px;
        margin-right: 73px;
        flex-shrink: 0;

        @include up-to-tablet2 {
          order: 2;
          width: 100%;
          height: 209px;
        }
      }

      &__form {
        @include up-to-tablet2 {
          order: 1;
        }

        input {
          height: 64px;
          background-color: #FFFFFF;
          box-shadow: 0px 24px 48px rgba(51, 9, 0, 0.08);
          border: 0;
          // border-radius: 16px;
          margin-bottom: 32px;
          padding: 0 28px;

          &::placeholder {
            color: rgba(102, 153, 255, 0.51);
          }

          @include up-to-tablet2 {
            height: 61px;
          }
        }

        button {
          display: block;
          width: 154px;
          margin: 10px auto 0 auto;

          @include up-to-tablet2 {
            margin-bottom: 57px;
          }
        }
      }
    }
  }

  &__faq {
    padding-top: 100px;
    padding-bottom: 109px;
    background-color: #6699FF;

    @include up-to-tablet2 {
      padding-top: 55px;
      padding-bottom: 69px;      
    }

    &__header-1 {
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-bottom: 18px;

      @include up-to-tablet2 {
        display: none;
      }
    }

    &__header-2 {
      font-size: 56px;
      line-height: 64px;
      text-align: center;
      letter-spacing: -2px;
      color: #FFFFFF;
      margin: 0 auto 52px auto;

      @include up-to-tablet2 {
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 44px;
        letter-spacing: 0;
        max-width: 185px;
      }
    }

    &__item {
      max-width: 825px;
      margin: 0 auto;
      padding: 41px 25px 24px 57px;

      @include up-to-tablet2 {
        padding: 21px 21px 28px 38px;
      }

      &--active {
        background: #FFFFFF;
        border-radius: 12px;
        margin-bottom: 24px;

        .acc-loans__faq__item__header {
          &__text {
            color: #0B62A0;
          }

          &__icon {
            color: #027AD0;
            border: 1px solid rgba(2, 122, 208, 0.51);
            transform: rotate(-90deg);
          }
        }

        .acc-loans__faq__item__content {
          max-height: 600px;
        }
      }

      &:not(.acc-loans__faq__item--active) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.16);

        .acc-loans__faq__item__header {
          &__text {
            color: #FFFFFF;
          }

          &__icon {
            color: #FFFFFF;
            border: 1px solid rgba(255, 255, 255, 0.295743);
            transform: rotate(90deg);
          }
        }

        .acc-loans__faq__item__content {
          // display: none;
          max-height: 0;
        }        
      }

      &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 17px;
        cursor: pointer;

        &__text {
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          max-width: calc(100% - 64px);

          @include up-to-tablet2 {
            font-size: 18px;
            max-width: calc(100% - 55px);
          }
        }

        &__icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;
          border-radius: 24px;
          box-sizing: border-box;
          font-size: 26px;
          font-family: monospace, monospace;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          @include up-to-tablet2 {
            margin-top: 16px;
          }
        }
      }

      &__content {
        font-size: 14px;
        line-height: 32px;
        color: #6699FF;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
      }
    }
  }

  &__below-faq-block {
    background-color: #6699FF;
    padding-bottom: 132px;

    @include up-to-tablet2 {
      padding-bottom: 37px;
    }

    &__area {
      background: #FFFFFF;
      border-radius: 20px;
      padding: 97px 103px;

      @include up-to-tablet2 {
        padding: 52px 28px;
      }
    }

    &__header {
      font-size: 56px;
      line-height: 64px;
      text-align: center;
      letter-spacing: -2px;
      color: #6699FF;
      max-width: 655px;
      margin: 0 auto 56px auto;

      @include up-to-tablet2 {
        font-size: 24px;
        line-height: 40px;
        letter-spacing: 0;
        max-width: 278px;
        margin-bottom: 27px;
      }
    }

    &__content {
      font-size: 18px;
      line-height: 28px;
      text-align: justify;
      color: #6699FF;

      @include up-to-tablet2 {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }

  &__resources {
    padding-top: 196px;
    color: #6699FF;
    margin-bottom: 208px;

    @include up-to-tablet2 {
      padding-top: 94px;
      margin-bottom: 60px;
    }

    &__header {
      font-size: 45px;
      line-height: 48px;
      text-align: center;
      letter-spacing: -1px;
      margin: 0 auto 39px auto;

      @include up-to-tablet2 {
        font-size: 24px;
        line-height: 40px;
        letter-spacing: 0;
      }
    }

    &__content {
      font-weight: 500;
      font-size: 18px;
      line-height: 40px;

      @include up-to-tablet2 {
        font-size: 16px;
      }
    }
  }

  &__locations {
    padding-bottom: 165px;

    @include up-to-tablet2 {
      padding-bottom: 100px;
    }

    &__header {
      font-size: 45px;
      line-height: 48px;
      text-align: center;
      letter-spacing: -1px;
      margin: 0 auto 41px auto;
      color: #6699FF;

      @include up-to-tablet2 {
        font-size: 24px;
        margin-bottom: 0;
      }
    }

    &__text {
      display: none;
      font-weight: 600;
      font-size: 12px;
      line-height: 25px;
      color: #6699FF;
      text-align: center;

      @include up-to-tablet2 {
        display: block;
        margin-bottom: 12px;
      }
    }

    &__map {
      max-width: 100%;

      &--normal {
        @include up-to-tablet2 {
          display: none;
        }
      }

      &--circles {
        display: none;

        @include up-to-tablet2 {
          display: block;
        }
      }
    }
  }

  &__bottom {
    margin-top: 252px;
    position: relative;
    background-color: #4f8af1;;
    height: 180px;

    @include up-to-tablet2 {
      height: 300px;
    }

    &__block {
      position: absolute;
      top: -252px;
      left: 0;
      right: 0;
      padding: 85px;
      background: url('../images/loans/bottom_bg.png') center bottom no-repeat #6699FF;
      border-radius: 20px;
      border: 10px solid #FFFFFF;
      text-align: center;

      @include up-to-tablet2 {
        padding: 97px 30px;
        left: 27px;
        right: 27px;
      }
    }

    &__header {
      font-size: 56px;
      line-height: 70px;
      text-align: center;
      letter-spacing: -2px;
      color: #FFFFFF;
      margin: 0 auto;

      @include up-to-tablet2 {
        font-size: 36px;
        line-height: 50px;
      }
    }

    &__button {
      min-width: 196px;
      margin-top: 37px;
    }
  }

  &__form__success--js {
    display: none;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    padding: 20px;
    color: $orange;
  }

  &__form__error--js {
    display: none;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    padding: 20px;
    color: $red;
  }
}
