:root {
  --fxo-color: rgb(102, 153, 255);
}
@media screen and (max-width:480px) {
    :root{
        --fxo-color:rgb(98, 100, 218);
    }
}
#fxo-messenger-iframe,
#fxo-widget-iframe,
.fxo-widget-iframe{
    display: none !important;
}
body.fxo-messenger-noscroll{
    overflow: initial !important;
}
body.fxo-messenger-fullscreen{
    position: initial;
}
#helpcrunch-container {
    font-family: "HelveticaNeueCyr", sans-serif !important;
    text-rendering: auto !important;
    font-smooth: auto !important;
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    -webkit-tap-highlight-color: transparent
}
#helpcrunch-container *::selection {
    background: #c3c7ce
}
#helpcrunch-container div, #helpcrunch-container p, #helpcrunch-container a, #helpcrunch-container figure, #helpcrunch-container img, #helpcrunch-container hr, #helpcrunch-container form, #helpcrunch-container textarea, #helpcrunch-container span, #helpcrunch-container time, #helpcrunch-container strong, #helpcrunch-container i, #helpcrunch-container pre {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    line-height: inherit;
    background: transparent;
    text-align: inherit;
    font-size: inherit;
    font-family: inherit
}
#helpcrunch-container input, #helpcrunch-container textarea {
    font-family: "HelveticaNeueCyr", sans-serif
}
#helpcrunch-container textarea, #helpcrunch-container textarea:focus, #helpcrunch-container input, #helpcrunch-container input:focus {
    outline: 0;
    border: 0;
    background: transparent;
    font-weight: normal
}
#helpcrunch-container button::-moz-focus-inner {
    border: 0
}
#helpcrunch-container a {
    text-decoration: none;
    color: inherit;
    outline: 0
}
#helpcrunch-container a:visited {
    color: inherit
}
#helpcrunch-container .helpcrunch-hide {
    display: none !important
}
#helpcrunch-container .helpcrunch-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ef174f;
    text-align: center;
    font-size: 11px;
    line-height: 22px;
    color: #fff;
    font-weight: bold;
    font-style: normal;
    font-family: "HelveticaNeueCyr", sans-serif
}
#helpcrunch-container .helpcrunch-modal-dialog {
    background-color: rgba(0, 69, 95, 0.2);
    position: fixed;
    z-index: 50;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    -webkit-animation: show-modal-dialog .24s .1s;
    animation: show-modal-dialog .24s .1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}
#helpcrunch-container .helpcrunch-modal-dialog.helpcrunch-modal-dialog-hidden {
    display: none;
    -webkit-animation: none;
    animation: none
}
#helpcrunch-container .helpcrunch-modal-dialog .helpcrunch-modal-content {
    padding: 35px;
    border-radius: 5px;
    border: 1px solid #bbb;
    background-color: #fff;
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90%;
    min-width: 120px;
    min-height: 120px;
    text-align: center;
    position: relative;
    cursor: default
}
#helpcrunch-container .helpcrunch-modal-dialog .helpcrunch-modal-content img {
    position: relative;
    z-index: 2;
    vertical-align: top
}
#helpcrunch-container .helpcrunch-modal-dialog .helpcrunch-modal-content .helpcrunch-btn-close {
    padding: 8px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    -webkit-transition: color .24s;
    transition: color .24s;
    color: #1d1d26;
    opacity: .3
}
#helpcrunch-container .helpcrunch-modal-dialog .helpcrunch-modal-content .helpcrunch-btn-close:hover {
    opacity: .6
}
#helpcrunch-container .helpcrunch-modal-dialog .helpcrunch-modal-content .helpcrunch-btn-close svg {
    vertical-align: top;
    width: 24px;
    height: 24px
}
#helpcrunch-container .helpcrunch-modal-dialog .helpcrunch-modal-content .helpcrunch-messages-preloader {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin: -22px 0 0 -22px
}
#helpcrunch-container .helpcrunch-widget-gradient-style {
    background-image: -webkit-linear-gradient(315deg, transparent 0, rgba(255, 255, 255, 0.4) 90%);
    background-image: linear-gradient(135deg, transparent 0, rgba(255, 255, 255, 0.4) 90%)
}
#helpcrunch-container.helpcrunch-trigger-button-show .helpcrunch-status-bar {
    -webkit-transition: opacity .24s, visibility 1ms;
    transition: opacity .24s, visibility 1ms;
    visibility: visible;
    opacity: 1;
    z-index: 11
}
#helpcrunch-container.helpcrunch-trigger-button-show .helpcrunch-status-bar .helpcrunch-badge {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity .3s .1s linear, -webkit-transform .3s .1s linear;
    transition: opacity .3s .1s linear, transform .3s .1s linear
}
#helpcrunch-container.helpcrunch-trigger-button-show .helpcrunch-status-bar .helpcrunch-status-bar-content {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform .24s cubic-bezier(0.38, 0.74, 0.24, 1), box-shadow .24s;
    transition: transform .24s cubic-bezier(0.38, 0.74, 0.24, 1), box-shadow .24s
}
#helpcrunch-container .helpcrunch-status-bar {
    position: fixed;
    z-index: 10;
    margin: 5px 35px 35px 5px;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: calc(100% - 10px);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .24s;
    transition: .24s
}
#helpcrunch-container.helpcrunch-maximum-size .helpcrunch-status-bar {
    margin: 5px;
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-status-bar-content {
    width: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform 1ms .1s cubic-bezier(0.38, 0.74, 0.24, 1), box-shadow .1s;
    transition: transform 1ms .1s cubic-bezier(0.38, 0.74, 0.24, 1), box-shadow .1s
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    background-color: #ef174f;
    text-align: center;
    font-size: 11px;
    line-height: 22px;
    color: #fff;
    font-weight: bold;
    font-style: normal
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-badge, #helpcrunch-container .helpcrunch-status-bar .helpcrunch-badge.helpcrunch-hide {
    display: block !important;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: none;
    transition: none
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-icon {
    cursor: pointer
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-icon>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-icon .helpcrunch-widget-icon-block {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    top: 0;
    left: 0;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
    -webkit-transition: box-shadow .24s;
    transition: box-shadow .24s;
    background-color: var(--fxo-color);
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-icon .helpcrunch-widget-icon {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-icon:hover .helpcrunch-widget-icon-block, #helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-icon:hover .helpcrunch-widget-type-icon-label {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3)
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-widget-type-icon-label {
    color: #fff;
    height: 44px;
    min-width: 44px;
    line-height: 44px;
    padding: 0 13px;
    margin-left: 13px;
    margin-top: 8px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "HelveticaNeueCyr", sans-serif;
    font-weight: 500;
    font-size: 14px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: box-shadow .24s;
    transition: box-shadow .24s;
    background-color: var(--fxo-color);
}
#helpcrunch-container.helpcrunch-maximum-size .helpcrunch-status-bar .helpcrunch-widget-type-icon-label {
    /*display: none;*/
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-widget-type-icon-text {
    margin-top: 1px;
    overflow: hidden;
    width: 100%;
    height: 43px;
    -ms-flex-item-align: start;
    align-self: flex-start;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-widget-type-icon-triangle {
    width: 0;
    height: 0;
    position: absolute;
    z-index: 11;
    top: 13px;
    border-width: 10px 6px 10px 6px;
    border-style: solid;
    border-top-color: transparent !important;
    border-color: var(--fxo-color);
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-button-wrap {
    width: auto;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    line-height: 1.43;
    font-weight: 500;
    font-family: "HelveticaNeueCyr", sans-serif;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2)
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-button-wrap:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3)
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-button-wrap .helpcrunch-button {
    padding: 17px 26px 15px 60px;
    min-width: 64px;
    height: 54px
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-button-wrap .helpcrunch-button span {
    display: block;
    min-height: 22px;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-widget-icon {
    position: absolute;
    left: 12px;
    top: 7px;
    width: 40px;
    height: 40px
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-widget-icon-wrapper {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}
#helpcrunch-container .helpcrunch-status-bar .helpcrunch-widget-icon-wrapper svg {
    display: block
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-tab {
    margin-bottom: 0
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-tab>div {
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-tab .helpcrunch-button-wrap {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-without-icon .helpcrunch-button, #helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-without-text .helpcrunch-button {
    padding-left: 26px
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-without-icon .helpcrunch-widget-icon {
    display: none
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-without-text .helpcrunch-widget-type-icon-label {
    display: none
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-without-text .helpcrunch-button>span {
    display: none
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-gradient .helpcrunch-button-wrap, #helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-gradient.helpcrunch-widget-type-icon .helpcrunch-widget-icon-block {
    background-image: -webkit-linear-gradient(315deg, transparent 0, rgba(255, 255, 255, 0.4) 90%);
    background-image: linear-gradient(135deg, transparent 0, rgba(255, 255, 255, 0.4) 90%)
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-style-rounded .helpcrunch-button-wrap, #helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-style-rounded .helpcrunch-widget-type-icon-label, #helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-style-rounded.helpcrunch-widget-type-icon .helpcrunch-widget-icon-block {
    border-radius: 20px
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-style-circle .helpcrunch-button-wrap, #helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-style-circle .helpcrunch-widget-type-icon-label, #helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-style-circle.helpcrunch-widget-type-icon .helpcrunch-widget-icon-block {
    border-radius: 60px
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-style-squared .helpcrunch-button-wrap, #helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-style-squared .helpcrunch-widget-type-icon-label, #helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-style-squared.helpcrunch-widget-type-icon .helpcrunch-widget-icon-block {
    border-radius: 5px
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-left {
    left: 0
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-left.helpcrunch-widget-type-icon>div {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-left.helpcrunch-widget-type-icon .helpcrunch-badge {
    right: auto;
    left: 45px
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-left .helpcrunch-widget-type-icon-triangle {
    left: -8px;
    border-left-color: transparent !important
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-right {
    right: 0
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-right .helpcrunch-widget-type-icon-label {
    margin-left: 0;
    margin-right: 13px
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-right .helpcrunch-widget-type-icon-triangle {
    right: -8px;
    border-right-color: transparent !important
}
#helpcrunch-container .helpcrunch-avatar {
    display: inline-block;
    background-color: #999;
    color: #fff !important;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    cursor: inherit;
    background-color: transparent
}
#helpcrunch-container .helpcrunch-avatar span {
    cursor: inherit
}
#helpcrunch-container .helpcrunch-avatar-users {
    line-height: 20px;
    font-size: 11px;
    height: 20px;
    margin-right: 10px;
    width: 22px
}
#helpcrunch-container .helpcrunch-avatar-profile {
    line-height: 64px;
    font-size: 27px;
    width: 64px;
    height: 64px;
    border-radius: 32px
}
#helpcrunch-container .helpcrunch-avatar-settings span {
    border-radius: 50%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 23px;
    height: 60px;
    line-height: 60px;
    margin: 15px;
    width: 60px
}
#helpcrunch-container .helpcrunch-avatar-settings span img {
    border-radius: 50%
}
#helpcrunch-container .helpcrunch-avatar-chat {
    border-radius: 50%;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    width: 32px
}
#helpcrunch-container .helpcrunch-avatar-chat img {
    border-radius: 50%
}
#helpcrunch-container .helpcrunch-avatar-auto-message {
    border-radius: 50%;
    font-size: 12px;
    height: 26px;
    line-height: 26px;
    margin-right: 5px;
    position: relative;
    top: -2px;
    vertical-align: middle;
    width: 26px
}
#helpcrunch-container .helpcrunch-avatar-auto-message img {
    border-radius: 50%
}
#helpcrunch-container .helpcrunch-avatar-auto-message-small {
    line-height: 22px;
    font-size: 11px;
    height: 22px;
    width: 22px
}
#helpcrunch-container .helpcrunch-avatar-bg-0 {
    background-color: #1abc9c
}
#helpcrunch-container .helpcrunch-avatar-bg-1 {
    background-color: #2ecc71
}
#helpcrunch-container .helpcrunch-avatar-bg-2 {
    background-color: #3d9cdc
}
#helpcrunch-container .helpcrunch-avatar-bg-3 {
    background-color: #f1c516
}
#helpcrunch-container .helpcrunch-avatar-bg-4 {
    background-color: #e68127
}
#helpcrunch-container .helpcrunch-avatar-bg-5 {
    background-color: #e75142
}
#helpcrunch-container .helpcrunch-avatar-bg-6 {
    background-color: #374c61
}
#helpcrunch-container .helpcrunch-avatar-bg-7 {
    background-color: #17b8ee
}
#helpcrunch-container .helpcrunch-avatar-bg-8 {
    background-color: #ec6988
}
#helpcrunch-container .helpcrunch-avatar-bg-9 {
    background-color: #9e5eb8
}
#helpcrunch-container .helpcrunch-avatar-bg-10 {
    background-color: #6a67ce
}
#helpcrunch-container .helpcrunch-avatar-bg-11 {
    background-color: #e03030
}
#helpcrunch-container .helpcrunch-avatar-bg-anonym {
    background-color: #bababa
}
#helpcrunch-container .helpcrunch-avatar-bg-gradient {
    background-image: -webkit-linear-gradient(136deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    background-image: linear-gradient(314deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0))
}
#helpcrunch-container .helpcrunch-opacity-03 {
    opacity: .3
}
#helpcrunch-container .helpcrunch-clearfix {
    *zoom: 1
}
#helpcrunch-container .helpcrunch-clearfix:before, #helpcrunch-container .helpcrunch-clearfix:after {
    content: close-quote;
    display: table
}
#helpcrunch-container .helpcrunch-clearfix:after {
    clear: both
}
#helpcrunch-container .helpcrunch-close-button, #helpcrunch-container .helpcrunch-minimize-button {
    color: #fff;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}
#helpcrunch-container.helpcrunch-maximum-size .helpcrunch-chat {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    margin: 0;
    background-color: var(--fxo-color);
}
#helpcrunch-container.helpcrunch-chat-fadein .helpcrunch-chat {
    z-index: 11;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    -webkit-transition: -webkit-transform .3s cubic-bezier(0.38, 0.74, 0.24, 1), visibility .3s, opacity .3s;
    transition: transform .3s cubic-bezier(0.38, 0.74, 0.24, 1), visibility .3s, opacity .3s
}
#helpcrunch-container.helpcrunch-chat-fadein .helpcrunch-chat-size-big .helpcrunch-chat {
    opacity: 1
}
#helpcrunch-container .helpcrunch-chat {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 5px 5px 0;
    z-index: 10;
    max-width: 370px;
    min-width: 240px;
    width: calc(100% - 10px);
    max-height: 620px;
    min-height: 300px;
    height: calc(100% - 5px);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px 5px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    visibility: hidden;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
    opacity: 0;
    -webkit-transition: -webkit-transform .24s, visibility .24s, opacity .24s;
    transition: transform .24s, visibility .24s, opacity .24s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-online-sign {
    height: 14px;
    width: 14px;
    position: absolute;
    right: -1px;
    top: -3px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    z-index: 2
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-online-sign:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid #fff
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-online-sign.helpcrunch-online {
    background-color: #60d885
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-online-sign.helpcrunch-offline {
    background-color: #f66
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-avatar-names-list {
    font-size: 14px;
    letter-spacing: .3px;
    font-weight: 500;
    color: #fff;
    /*margin: 3px auto 0;*/
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
    white-space: initial;
}
#helpcrunch-container .helpcrunch-chat .typing-insight-dots {
    margin-left: 5px
}
#helpcrunch-container .helpcrunch-chat .typing-insight-dots .typing-insight-dot, #helpcrunch-container .helpcrunch-chat .typing-insight-dots:before, #helpcrunch-container .helpcrunch-chat .typing-insight-dots:after {
    display: inline-block;
    vertical-align: middle;
    width: 5px;
    height: 5px;
    margin-right: 3px;
    background-color: #fff;
    border-radius: 50%;
    -webkit-animation-name: dot-pulse;
    animation-name: dot-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    text-align: left
}
#helpcrunch-container .helpcrunch-chat .typing-insight-dots:before, #helpcrunch-container .helpcrunch-chat .typing-insight-dots:after {
    content: ""
}
#helpcrunch-container .helpcrunch-chat .typing-insight-dots .typing-insight-dot {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}
#helpcrunch-container .helpcrunch-chat .typing-insight-dots:after {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header {
    -ms-flex-negative: initial !important;
    flex-shrink: initial !important;
    height: 100%;
    min-height: 64px;
    width: 100%;
    z-index: 2;
    text-align: center;
    position: relative;
    border-radius: 5px 5px 0 0
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-agent-name {
    font-size: 14px;
    letter-spacing: .3px;
    font-weight: 500;
    color: #fff;
    margin: 3px auto 0;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-all-agents {
    margin-top: 13px;
    display: none
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-all-agents .helpcrunch-avatar-with-name-wrap {
    text-align: center !important;
    font-size: 14px;
    letter-spacing: .3px;
    font-weight: 500;
    color: #fff;
    margin: 3px auto 0;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
    overflow: visible
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-all-agents .helpcrunch-avatar {
    width: 50px;
    height: 50px;
    font-size: 14px;
    line-height: 54px;
    position: relative;
    cursor: default;
    border-width: 2px;
    border-style: solid;
    box-sizing: content-box;
    margin: 0 -7px;
    vertical-align: top
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-all-agents .helpcrunch-avatar.helpcrunch-more-agents {
    background-color: #fff
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-all-agents .helpcrunch-avatar.helpcrunch-more-agents .helpcrunch-more-agents-counter span {
    font-size: 14px !important
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-all-agents .helpcrunch-avatar img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 25px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-all-agents .helpcrunch-logo-wrap {
    display: block !important;
    margin-top: -4px
}
@media screen and (max-device-width:1024px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-all-agents {
        margin-right: 0
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-logo-wrap {
    -webkit-transform: translate(0, 70px);
    -ms-transform: translate(0, 70px);
    transform: translate(0, 70px)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-logo-wrap .helpcrunch-logo {
    background-color: #fff;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    -webkit-transition: .3s;
    transition: .3s;
    vertical-align: top
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-logo-wrap .helpcrunch-logo-message {
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-logo-wrap .helpcrunch-logo-message:after, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-logo-wrap .helpcrunch-logo-message:before {
    margin-top: -60px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-logo-wrap .helpcrunch-logo-message:after {
    content: "";
    position: absolute;
    right: 30px;
    width: 36px;
    height: 86px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAABWCAYAAACn6jRSAAAAAXNSR0IArs4c6QAAAyFJREFUaAXtWt9LHDEQ7tZDaIVK+yT0B5VS+gukLz6U/vl96INvBbGittCe4JOioILQXr/vOnNMs7nsriZ7K52BmGQyyXz5kk1mva3uGJlMJndRfYP0RNRj5DtVVf2WevFsFHh4i/pzo1uX8rbRFS2SESuPbUXKylakKb8qBJTfQ8cRQ0DcM6HEdKFNtnq4h77KyLpMBLOTzZsP5Aw4A86AM+AM3HIGEAB+ZEpNI7xcU7Y52h42DRKGH032xduLMoTlWcYMniEt2ZlA/0rqv5D/QMx+pe1FAcEJ46rX6szkL02ZLxDftF4a0BiOuC2UIQWyJwDIEG1mUhSQLMW+esNSTQFBv6u6MP+/NnU4e9RPIjpXOQPOgDPgDKQYqFKNsTbcRw+gZ6DF0IJhwwnupjPkWaQ1IAB5BI/vkFYjnk+h2waw40hbJ1UrQADzFKNuIKXsJ2j/AlA/OyEIjBvDD2HGgmFAdYR0gXQfaQ2JUQPBbsD+/CZMNQKCEy6TMsO9sgWHl8inAgD3UNhE4t6iHe0/IV1LkvEQnNGJ7hky8w8YehRwWyiynbIq/f7WOv5NAsJY9rXlyDJj/Yiey6hi+6muVd4EiI+2CvdMSmy77ZfqU2trAjR7PUFPbuCU2HbbL9Wn1tYEyIaca7KBa4OInk+biu2nulZ5EhD2Bp8qHnoU2m6GoKTOp0zHOpV+7NNZ9HGe2xEOeUJ/QFLbeecQx+Dh+BmAjlm5jqiTZF+A6u2kbgWIaIWpYdxllj4A42HJc6bIbW99edkZcAZKMND6HMrtHMcHr5raNwJtIsbcWHS86DcCeiGqUZ959BuBRQKKTn6RgMYRRONF7iH/RiCyIq5yBpwBZ2D4DMyNhxCv8KfJdaTveBPVY774jFKX6wt450+TzHuTFCBlT/NeQKUA9QIgdDI4QCNsXi4Jw8mVEK3WYaM//KvqHIVDbHb++yWrMEAjmPcNo+rv7aFZLOoLbTrVh7dkgH8oUwiXzLKiXyLobKdLppWc+Uj2QY167JsZINjM/RIhJxiONbglu1WA9JHWPPfqRMdLMXSAHvychnlv8gd51LaKfcussAAAAABJRU5ErkJggg==)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-logo-wrap .helpcrunch-logo-message:before {
    content: "";
    position: absolute;
    left: 30px;
    width: 40px;
    height: 85px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAABVCAYAAAA7QqZyAAAAAXNSR0IArs4c6QAAA4hJREFUaAXtmstrFTEUxjsqKCiK1uLCu2kFRQtal3bRP96FbqWgRcHH4nYht1UUBQX1+n3xnHCamUyHaTJGmwO5eZ1JfnNOHjM306wUIsvl8gxQ7iHMBGmO+MU5yZQQEW7dgLg0qUsRtZzlmZUEaMF8uiRAjrlQ5iWNwT2hU1e7SRIS13y1QLVAtcBps0CDp4gbuOn7cuO7TdO8L8kI3OoId0GCghbDWNJe3GkUAu4ifJPAdJVqgWoBY4HGpLMlsdaeR+PXEbicUTgpD7Dmfne5np+sgAC7gr7vIqxFGBYo3wPop0j9SjZAwG2g081Yx0H5c0C+CcpcNgtgB9wSvR0ifBaIy4hXEWz/nZDJX5rErdZyHwHyDBb6InAugt4lJLYQrkr5JsoOQ3fn2Oo45lQI9ySEY6WUPUWSOir2WleWFBAW4GzVCUG30nK/tPcwRt1P6iBQl7ImbfzJ4TcpINrjUqJCdx1xq1bYWHQ4PlVsG8kBdZ1jZzohtOO+2OraNpID9kGMqkvtYu4QKlxKhorVtW0kt+CBIVqVpcQUtZOiwzVRxbaRFhADnnsrty8KF+EtAES9JHVcC3XBXkgbvN5J9GJVGBHrv1S8lIvwdpclpWxbdKhLsde6AiV3mVQ/6HwDbdndZPRWlwWQN9oB2Xf/nfswL8gGyMYBWe7jFgFVADr6gVXbqHG1QLVAtUAmCxy7UGMN43vCOsJbbOStvTITl292yMPCLWifRWA8uQwBVCtrPCnkEMBJgcLOigf0/yxgMtCFNxEuhneheejc0bTEXxHvY/Loe21QffKsB0RThHt4TJO3I/U8280i/46Lcfv7YoLQxdZqrwIzORcHZUmz3sUyjlquwrjzgNB5mbT3AY0V7+L/AlCXEI0HOCadyhALvkZ3/B+PcZVqgWqBaoFqgWqBaoFTZoG/8q6Lh2A+pPBsRA9weBTGs73WwePkgIAj2AOE8NWCrw88Hf2A2MukgIC7hp55NhLrl8+cPF/2kEOeB/3dnCQhbrWnSj/Q3jsJTFMIfuR0yr80ueq8P3StupVAj2EpupXHFfygYgeBPNSh7gIh7VkdG+wRnRBUmSscM5K2b5RedzIXE2SMTAloT9VncKu6my5mWj+R53143SnHIL9L4JgjDPvdAZi6lXDKQh3/DUNsukMnvQCIg/8RQqzf1jITU0xPJy0KZJkLtd41IAdvdb8B5F3wE+XSRc4AAAAASUVORK5CYII=)
}
@media screen and (max-height:560px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-logo-wrap {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        transform: translate(0, 20px)
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper {
    display: inline-block;
    -webkit-transform: translate(0, 140px);
    -ms-transform: translate(0, 140px);
    transform: translate(0, 140px)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-general-data-message {
    padding: 0 20px;
    margin-bottom: 34px;
    white-space: pre-wrap;
    color: #fff;
    text-align: center;
    font-size: 14px
}
@media screen and (max-height:420px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-general-data-message {
        display: none
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-online-agents-block {
    text-align: center
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-online-agents-block div.helpcrunch-avatar-wrap {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-left: 30px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-online-agents-block div.helpcrunch-avatar-wrap .helpcrunch-avatar-view-wrap {
    text-align: center !important
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-online-agents-block div.helpcrunch-avatar-wrap:first-child {
    margin-left: 0
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-online-agents-block .helpcrunch-agent-name {
    text-align: center;
    position: absolute;
    top: calc(100% + 5px);
    left: -10px;
    width: 70px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-online-agents-block figure {
    display: inline-block;
    vertical-align: top;
    height: 50px;
    width: 50px;
    position: relative;
    line-height: 53px;
    font-weight: 300;
    font-size: 17px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-online-agents-block figure:nth-child(2) {
    margin-left: 30px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-online-agents-block figure:nth-child(3) {
    margin-left: 30px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-online-agents-block figure img {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    overflow: hidden
}
@media screen and (max-height:560px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper {
        -webkit-transform: translate(0, 70px);
        -ms-transform: translate(0, 70px);
        transform: translate(0, 70px)
    }
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-general-data {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        transform: translate(0, -20px)
    }
}
@media screen and (max-height:310px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper {
        -webkit-transform: translate(0, 56px);
        -ms-transform: translate(0, 56px);
        transform: translate(0, 56px)
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-btn-close, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-btn-minimize {
    padding: 10px;
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-btn-close svg, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-btn-minimize svg {
    opacity: .8 !important;
    vertical-align: top;
    -webkit-transition: .24s;
    transition: .24s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-btn-close:hover svg, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-btn-minimize:hover svg {
    opacity: 1 !important
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-btn-minimize {
    height: 23px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-btn-minimize svg {
    width: 20px;
    height: 20px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap {
    height: 0;
    opacity: 0;
    width: 100%;
    background-color: #f3f5f8;
    position: absolute;
    bottom: 0;
    -webkit-transition: height .3s;
    transition: height .3s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap .helpcrunch-widget-waves {
    width: 100%;
    position: absolute;
    top: -43px;
    overflow: hidden;
    height: 0
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap .helpcrunch-widget-waves svg {
    width: 100%;
    height: 49px;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
}
@media screen and (min-width:381px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap .helpcrunch-widget-waves {
        top: -45px
    }
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap .helpcrunch-widget-waves svg {
        height: 60px
    }
}
@media screen and (max-height:560px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap {
        display: none
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-control {
    opacity: .3;
    -webkit-transition: .24s;
    transition: .24s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #f3f5f8
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-wrapper {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages {
    -webkit-box-flex: 1;
    flex: 1 1 0;
    -ms-flex: 1 1 1%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages .helpcrunch-chat-load-more-link {
    display: block;
    font-size: 85%;
    text-align: center;
    padding: 15px 10px 0;
    cursor: pointer;
    color: #7bbad9;
    -webkit-transition: .24s;
    transition: .24s;
    background-color: var(--fxo-color);
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages .helpcrunch-chat-load-more-link:hover {
    opacity: .7
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages .helpcrunch-chat-day {
    padding: 0 10px 10px 10px;
    margin-top: 20px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages .helpcrunch-chat-day:last-child {
    border-bottom: 0
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages .helpcrunch-chat-day .helpcrunch-chat-day-title {
    text-align: center;
    margin-bottom: 10px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages .helpcrunch-chat-day .helpcrunch-chat-day-title span {
    display: inline-block;
    color: #666;
    font-size: 12px;
    line-height: 12px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages .helpcrunch-chat-message {
    margin: 15px 0;
    width: 100%;
    position: relative
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages .helpcrunch-chat-message .helpcrunch-avatar {
    width: 25px !important;
    height: 25px !important;
    font-weight: 100;
    line-height: 26px;
    font-size: 10px;
    cursor: default
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages .helpcrunch-sticker .helpcrunch-avatar {
    bottom: 3px !important
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-notification-style {
    padding: 20px 10px 16px 60px;
    margin: 10px;
    position: relative;
    color: #666;
    font-size: 14px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    word-wrap: break-word;
    white-space: pre-wrap;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-message-notification {
    padding: 20px 10px 16px 60px;
    margin: 10px;
    position: relative;
    color: #666;
    font-size: 14px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    word-wrap: break-word;
    white-space: pre-wrap;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-message-notification>svg {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    left: 14px;
    width: 34px;
    height: 34px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-message-notification>span img {
    max-width: 100%
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-message-notification>span a {
    text-decoration: underline
}
@media screen and (max-height:420px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-message-notification {
        padding: 12px 10px 8px 60px
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-waiting-message {
    padding: 20px 10px 16px 60px;
    margin: 10px;
    position: relative;
    color: #666;
    font-size: 14px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    word-wrap: break-word;
    white-space: pre-wrap;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-waiting-message .helpcrunch-messages-preloader {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    left: 8px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating {
    display: inline-block;
    width: 90px;
    vertical-align: top;
    cursor: pointer;
    opacity: 0;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
    -webkit-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating:nth-child(1) {
    -webkit-transition-delay: .6s;
    transition-delay: .6s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating:nth-child(2) {
    -webkit-transition-delay: .3s;
    transition-delay: .3s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating:hover .helpcrunch-rating-border {
    top: -5px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating:hover.helpcrunch-rating-poor .helpcrunch-rating-text {
    color: #f66
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating:hover.helpcrunch-rating-average .helpcrunch-rating-text {
    color: #f8c82a
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating:hover.helpcrunch-rating-great .helpcrunch-rating-text {
    color: #60d885
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating:hover.helpcrunch-selected-rating .helpcrunch-rating-border {
    top: 0
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-block {
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-block-wrapper {
    padding: 20px 10px 16px 60px;
    margin: 10px;
    position: relative;
    color: #666;
    font-size: 14px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    word-wrap: break-word;
    white-space: pre-wrap;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0 10px;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: .6s 1s cubic-bezier(0.48, 0.65, 0.04, 1);
    transition: .6s 1s cubic-bezier(0.48, 0.65, 0.04, 1)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-block-wrapper-show {
    visibility: visible;
    height: 170px;
    margin: 10px;
    -webkit-transition: .6s cubic-bezier(0.48, 0.65, 0.04, 1);
    transition: .6s cubic-bezier(0.48, 0.65, 0.04, 1)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-block-wrapper-show .helpcrunch-rating-block .helpcrunch-rating {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-block-wrapper-show .helpcrunch-rating-block .helpcrunch-rating:nth-child(1) {
    -webkit-transition-delay: .5s;
    transition-delay: .5s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-block-wrapper-show .helpcrunch-rating-block .helpcrunch-rating:nth-child(2) {
    -webkit-transition-delay: .8s;
    transition-delay: .8s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-block-wrapper-show .helpcrunch-rating-block .helpcrunch-rating:nth-child(3) {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-block-wrapper .helpcrunch-center {
    text-align: center
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-block .helpcrunch-rating-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-text {
    display: block;
    font-size: 12px;
    -webkit-transition: .24s;
    transition: .24s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-text-wrapper {
    width: 250px;
    margin: 0 auto;
    padding-top: 13px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-text-wrapper .helpcrunch-rating-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    max-height: 51px;
    overflow: hidden
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-border {
    border-radius: 50%;
    padding: 6px;
    height: 71px;
    margin-bottom: 2px;
    position: relative;
    top: 0;
    -webkit-transition: .24s;
    transition: .24s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form {
    position: relative;
    width: 100%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: #fff;
    box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.05);
    z-index: 11
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-form-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    opacity: .14;
    z-index: -2
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper {
    cursor: text;
    width: 100%;
    display: block;
    position: relative;
    min-height: 70px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-load-file-btn-wrap {
    position: absolute;
    visibility: hidden;
    bottom: 0;
    left: 0;
    text-align: center;
    cursor: pointer;
    padding: 23px 0 0 10px;
    height: 70px;
    width: 0;
    overflow: hidden;
    color: #3b444f;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: width .4s, visibility .4s;
    transition: width .4s, visibility .4s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-load-file-btn-wrap:hover .helpcrunch-chat-control {
    opacity: .6
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-load-file-btn-wrap svg {
    width: 20px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-load-file-btn-wrap input[type="file"] {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    visibility: hidden;
    position: absolute
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper.helpcrunch-load-file-active:not(.hide-attachments) .helpcrunch-chat-textarea-wrap {
    width: calc(100% - 138px) !important;
    margin-left: 45px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper.helpcrunch-load-file-active:not(.hide-attachments) .helpcrunch-load-file-btn-wrap {
    visibility: visible;
    width: 37px;
    -webkit-transition: width .4s, visibility .01s;
    transition: width .4s, visibility .01s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper {
    float: left;
    padding: 27px 7px 25px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-chat-textarea-wrap {
    width: calc(100% - 114px) !important;
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    min-height: 60px;
    -webkit-transition: width .4s, margin .4s;
    transition: width .4s, margin .4s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper textarea {
    display: inline-block;
    max-height: 94px;
    min-height: 59px;
    resize: none;
    overflow-x: hidden;
    overflow-y: scroll;
    box-shadow: none;
    width: calc(100% + 20px) !important;
    padding: 0 20px 0 0;
    font-family: "HelveticaNeueCyr", sans-serif;
    font-size: 14px;
    color: #3b444f;
    vertical-align: bottom;
    text-align: left;
    letter-spacing: .3px;
    max-width: none !important
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper textarea::-webkit-input-placeholder {
    letter-spacing: .3px;
    color: #d6d6d6;
    font-family: "HelveticaNeueCyr", sans-serif
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper textarea:-ms-input-placeholder {
    letter-spacing: .3px;
    color: #d6d6d6;
    font-family: "HelveticaNeueCyr", sans-serif
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper textarea::placeholder {
    letter-spacing: .3px;
    color: #d6d6d6;
    font-family: "HelveticaNeueCyr", sans-serif
}
@media screen and (max-height:440px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper textarea {
        max-height: 60px
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-chat-control-wrap {
    position: absolute;
    width: 86px;
    height: 70px;
    bottom: 0;
    right: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-chat-control-wrap .helpcrunch-emoji-picker-wrapper svg {
    vertical-align: top
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper {
    display: inline-block;
    position: relative;
    z-index: 15;
    cursor: pointer;
    color: #3b444f
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper:hover .helpcrunch-chat-control {
    opacity: .6
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-picker {
    height: 100%;
    padding: 0 8px;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-picker .helpcrunch-emoji-group {
    width: 296px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-picker .helpcrunch-emoji-group-title {
    line-height: 20px;
    margin: 15px 0 5px 5px;
    font-weight: 500;
    color: #3b444f
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-picker .helpcrunch-emoji-group .helpcrunch-emoji-image {
    display: inline-block;
    cursor: pointer;
    font-size: 22px;
    height: 22px;
    width: 22px;
    line-height: 26px;
    padding: 5px;
    font-family: Verdana !important;
    box-sizing: content-box
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-picker .helpcrunch-emoji-group .helpcrunch-emoji-image:nth-of-type(10n+10) {
    margin-right: 0
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-picker-box-wrap {
    display: none;
    cursor: default;
    background-color: #fff;
    position: absolute;
    bottom: 58px;
    right: -31px;
    height: 200px;
    width: 320px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-picker-box-wrap.helpcrunch-active {
    display: block;
    opacity: 1
}
@media screen and (max-width:370px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-picker-box-wrap {
        right: -56px
    }
}
@media screen and (max-height:290px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-picker-box-wrap {
        height: 170px
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-switcher {
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 0;
    margin-left: 0
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-image {
    display: inline-block !important;
    width: 28px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-send-btn-wrap {
    cursor: default;
    position: relative;
    float: right;
    padding: 18px 15px 18px 7px;
    color: #fff
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-send-btn-wrap .helpcrunch-chat-send-btn-circle {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    -webkit-transition: .24s;
    transition: .24s;
    background-color: var(--fxo-color);
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-send-btn-wrap svg {
    position: absolute;
    z-index: 2;
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -8px;
    right: 23px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-send-btn-wrap.helpcrunch-active {
    opacity: 1;
    cursor: pointer
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-send-btn-wrap.helpcrunch-active:hover .helpcrunch-chat-send-btn-circle {
    opacity: .8
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-send-btn {
    -webkit-transition: opacity .24s;
    transition: opacity .24s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-active {
    opacity: .6
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-mobile .helpcrunch-chat-send-btn-wrap.helpcrunch-active .helpcrunch-chat-send-btn-circle {
    opacity: 1 !important
}
#helpcrunch-container .helpcrunch-chat:not(.helpcrunch-state-guest) .helpcrunch-chat-send-btn-wrap:not(.helpcrunch-active) .helpcrunch-chat-send-btn-circle {
    background-color: #f3f5f8 !important
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-welcome-text {
    color: #666;
    font-size: 14px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 16px 28px;
    word-wrap: break-word;
    white-space: pre-wrap;
    margin: 12px auto 0;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 350px
}
@media screen and (max-width:370px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-welcome-text {
        margin: 12px 10px 0;
        width: auto;
        padding: 16px 18px
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-y: auto;
    background-color: #f3f5f8;
    -webkit-overflow-scrolling: touch
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 10px 38px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 370px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form div {
    position: relative;
    margin-top: 10px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form div:after {
    content: attr(data-arrow-top-tip);
    text-align: center;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 1px 8px 0;
    background: #f66;
    color: #fff;
    z-index: 2;
    font-size: 11px;
    line-height: 14px;
    border-radius: 5px 0 5px 0;
    white-space: nowrap;
    word-wrap: normal;
    min-width: 50px;
    -webkit-transition: .24s;
    transition: .24s;
    box-sizing: border-box;
    max-width: calc(100% - 6px);
    text-overflow: ellipsis;
    overflow: hidden
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form div .helpcrunch-input-requred {
    position: absolute;
    right: 20px;
    top: 18px;
    display: inline-block;
    color: #ed1c05;
    font-size: 13px;
    font-weight: bold
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form div span.helpcrunch-error {
    color: #f66 !important
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=text], #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=email] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 46px;
    line-height: 46px;
    box-sizing: border-box;
    box-shadow: none;
    padding-top: 4px;
    padding-left: 20px;
    padding-right: 28px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #fff;
    font-family: "HelveticaNeueCyr", sans-serif;
    font-size: 14px;
    color: #3b444f;
    -webkit-transition: .24s;
    transition: .24s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=text]:hover, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=email]:hover, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=text]:focus, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=email]:focus {
    border-color: #adb5bf
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=text]:invalid, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=email]:invalid {
    box-shadow: none;
    border-color: #e0e0e0
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=text].helpcrunch-error, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=email].helpcrunch-error {
    border-color: #f66 !important;
    color: #f66 !important
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=text]::-webkit-input-placeholder, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=email]::-webkit-input-placeholder {
    color: #999;
    line-height: 46px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=text]:-ms-input-placeholder, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=email]:-ms-input-placeholder {
    color: #999;
    line-height: 46px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=text]::placeholder, #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=email]::placeholder {
    color: #999;
    line-height: 46px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-error:hover:after {
    visibility: visible !important;
    opacity: 1
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=submit] {
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-appearance: none;
    text-transform: initial !important;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 52px;
    padding-top: 5px;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: .24s;
    transition: .24s;
    background-color: var(--fxo-color);
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=submit]:hover {
    opacity: .9
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=submit]:active {
    opacity: .7
}
@media screen and (max-width:370px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form {
        padding: 10px 28px
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section {
    color: #3b444f;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    text-align: left;
    padding: 5px;
    overflow: hidden;
    word-wrap: break-word;
    white-space: pre-wrap;
    margin-top: 15px;
    position: relative;
    cursor: pointer
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section:hover .checkbox {
    border-color: #adb5bf
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section .checkbox {
    display: inline-block;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    border-radius: 4px;
    width: 18px;
    height: 18px;
    vertical-align: bottom;
    margin-right: 8px;
    text-align: center;
    vertical-align: top;
    cursor: pointer
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section .checkbox svg {
    padding-top: 2px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section .helpcrunch-chat-gdpr-label {
    padding-left: 5px;
    font-size: 12px;
    width: calc(100% - 30px);
    text-overflow: ellipsis;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section .helpcrunch-chat-gdpr-label a {
    margin-left: 5px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section label {
    cursor: pointer
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section .helpcrunch-chat-gdpr-link {
    text-decoration: underline;
    cursor: pointer
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section .helpcrunch-input-requred {
    top: -1px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section.helpcrunch-gdpr-error {
    color: #f66 !important
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section.helpcrunch-gdpr-error .checkbox {
    border-color: #f66 !important
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-chat-position-left {
    right: auto;
    left: 0
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    z-index: 11;
    position: relative;
    height: 30px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #666;
    background-color: #fff;
    box-shadow: inset 0 1px 0 0 rgba(222, 222, 222, 0.5)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-footer a {
    text-decoration: underline;
    font-size: 12px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-footer a:hover {
    text-decoration: none
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    text-align: left;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility .4s ease-in-out, opacity .4s ease-in-out;
    transition: visibility .4s ease-in-out, opacity .4s ease-in-out;
    padding-left: 10px;
    color: inherit
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat-hidden {
    visibility: hidden;
    opacity: 0
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat-hidden+.helpcrunch-chat-powered {
    right: 50%;
    -webkit-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    transform: translate(50%, 0)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat>span {
    cursor: pointer;
    color: inherit;
    -webkit-transition: .24s;
    transition: .24s;
    line-height: 32px;
    display: inline-block;
    vertical-align: top
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat>span:hover {
    color: #222
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat-popup-confirm {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 32px;
    z-index: 12;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    min-height: 104px;
    box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.05)
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat-text {
    font-size: 14px;
    text-align: center;
    color: #3b444f;
    padding: 10px;
    min-height: 63px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat-buttons {
    padding: 0 10px 10px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat-buttons>div {
    text-align: center;
    float: left;
    width: calc(50% - 5px);
    color: #fff;
    font-size: 14px;
    letter-spacing: .3px;
    font-weight: 500;
    line-height: 29px;
    border-radius: 2px;
    background-color: #3b444f;
    padding: 2px 0 0;
    cursor: pointer;
    -webkit-transition: .24s;
    transition: .24s
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat-buttons>div:hover {
    opacity: .8
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-end-chat-buttons>div:first-child {
    margin-right: 10px
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-powered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: inherit;
    line-height: 32px;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 10px;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: right .4s ease-in-out, -webkit-transform .4s ease-in-out;
    transition: right .4s ease-in-out, transform .4s ease-in-out;
    color: inherit;
    opacity: .6
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-powered:hover {
    opacity: .7
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-powered.guest-branding {
    opacity: .9
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-chat-powered.guest-branding:hover {
    opacity: 1
}
@media screen and (max-height:619px) {
    #helpcrunch-container .helpcrunch-chat {
        height: 100%;
        max-height: 100%;
        border-radius: 0;
        margin-top: 0
    }
    #helpcrunch-container .helpcrunch-chat .helpcrunch-chat-header {
        border-radius: 0
    }
}
@media screen and (max-height:420px) {
    #helpcrunch-container .helpcrunch-chat .helpcrunch-footer {
        height: 24px;
        padding-top: 4px
    }
    #helpcrunch-container .helpcrunch-chat .helpcrunch-footer .helpcrunch-end-chat-popup-confirm {
        bottom: 26px
    }
    #helpcrunch-container .helpcrunch-chat .helpcrunch-footer .helpcrunch-end-chat>span, #helpcrunch-container .helpcrunch-chat .helpcrunch-footer .helpcrunch-chat-powered {
        line-height: 26px
    }
}
#helpcrunch-container .helpcrunch-chat .helpcrunch-icon-emoji-picker {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjRweCIgdmVyc2lvbj0iMS4xIiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiPgogIDxnPgogICAgPGcgZmlsbD0iIzFEMUQxQiI+CiAgICAgIDxwYXRoIGQ9Ik0zMiwwQzE0LjM1NSwwLDAsMTQuMzU1LDAsMzJzMTQuMzU1LDMyLDMyLDMyczMyLTE0LjM1NSwzMi0zMlM0OS42NDUsMCwzMiwweiBNMzIsNjAgICAgQzE2LjU2MSw2MCw0LDQ3LjQzOSw0LDMyUzE2LjU2MSw0LDMyLDRzMjgsMTIuNTYxLDI4LDI4UzQ3LjQzOSw2MCwzMiw2MHoiIGZpbGw9IiM5MmQ1ZTgiLz4KICAgICAgPGNpcmNsZSBjeD0iMjAuNTE4IiBjeT0iMjEuMzYxIiByPSI0LjMzOCIgZmlsbD0iIzkyZDVlOCIvPgogICAgICA8Y2lyY2xlIGN4PSI0My40OCIgY3k9IjIxLjM2MSIgcj0iNC4zMzgiIGZpbGw9IiM5MmQ1ZTgiLz4KICAgICAgPHBhdGggZD0ibTUyLjU0MSwzNi41NjhjLTEuMDUzLTAuMzE2LTIuMTcyLDAuMjg3LTIuNDg4LDEuMzQ0LTAuMDM1LDAuMTE5LTMuNzM5LDExLjk0Ny0xOC4wNTMsMTEuOTQ3LTE0LjIxOSwwLTE3LjkwNC0xMS40NjctMTguMDU1LTExLjk1NS0wLjMyLTEuMDU1LTEuNDQxLTEuNjUtMi40ODYtMS4zMzYtMS4wNTksMC4zMTctMS42NiwxLjQzMi0xLjM0NCwyLjQ4OSAwLjA0NSwwLjE0OCA0LjYyNywxNC44MDIgMjEuODg1LDE0LjgwMnMyMS44NC0xNC42NTQgMjEuODg1LTE0LjgwM2MwLjMxNi0xLjA1Ni0wLjI4NS0yLjE3MS0xLjM0NC0yLjQ4OHoiIGZpbGw9IiM5MmQ1ZTgiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=) !important;
    background-repeat: no-repeat
}
#helpcrunch-container .helpcrunch-chat span.helpcrunch-emoji {
    background-size: cover;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle
}
#helpcrunch-container .helpcrunch-chat .ss-content {
    right: 0 !important
}
#helpcrunch-container .helpcrunch-drop-file {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(249, 250, 252, 0.97);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    -webkit-transition: opacity .3s, visibility .3s, height 1ms .3s;
    transition: opacity .3s, visibility .3s, height 1ms .3s
}
#helpcrunch-container .helpcrunch-drop-file-show {
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    opacity: 1;
    visibility: visible;
    height: 100%
}
#helpcrunch-container .helpcrunch-drop-file input[type="file"] {
    position: absolute;
    z-index: 12;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}
#helpcrunch-container .helpcrunch-drop-file h3, #helpcrunch-container .helpcrunch-drop-file p {
    font-size: 14px;
    line-height: 18px;
    margin: 0
}
#helpcrunch-container .helpcrunch-drop-file h3 {
    font-weight: 500;
    padding-bottom: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top: 60px;
    color: #222
}
#helpcrunch-container .helpcrunch-drop-file p {
    width: 154px;
    margin: 0 auto;
    color: #3b444f;
    padding-bottom: 20px
}
#helpcrunch-container .helpcrunch-drop-file.drop-entered .helpcrunch-drop-file-images .helpcrunch-file-icon:first-child {
    -webkit-transform: rotate(-20deg) scale(0.8) translate(-20px, -16px);
    -ms-transform: rotate(-20deg) scale(0.8) translate(-20px, -16px);
    transform: rotate(-20deg) scale(0.8) translate(-20px, -16px)
}
#helpcrunch-container .helpcrunch-drop-file.drop-entered .helpcrunch-drop-file-images .helpcrunch-file-icon:nth-child(2) {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    transform: translate(0, -20px)
}
#helpcrunch-container .helpcrunch-drop-file.drop-entered .helpcrunch-drop-file-images .helpcrunch-file-icon:last-child {
    -webkit-transform: rotate(20deg) scale(0.8) translate(20px, -16px);
    -ms-transform: rotate(20deg) scale(0.8) translate(20px, -16px);
    transform: rotate(20deg) scale(0.8) translate(20px, -16px)
}
#helpcrunch-container .helpcrunch-drop-file.drop-entered .helpcrunch-drop-file-button {
    -webkit-transform: translate(0, -8px);
    -ms-transform: translate(0, -8px);
    transform: translate(0, -8px);
    background-color: var(--fxo-color);
}
#helpcrunch-container .helpcrunch-drop-file-images {
    margin-top: 20px;
    height: 90px;
    position: relative;
    width: 100%
}
#helpcrunch-container .helpcrunch-drop-file-images .helpcrunch-file-icon {
    position: absolute;
    bottom: 0;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transition: .4s;
    transition: .4s
}
#helpcrunch-container .helpcrunch-drop-file-images .helpcrunch-file-icon:first-child {
    left: 50%;
    margin-left: -82px;
    color: #fc3d39;
    -webkit-transform: rotate(-20deg) scale(0.8);
    -ms-transform: rotate(-20deg) scale(0.8);
    transform: rotate(-20deg) scale(0.8)
}
#helpcrunch-container .helpcrunch-drop-file-images .helpcrunch-file-icon:nth-child(2) {
    left: 50%;
    margin-left: -33px;
    color: #4184f3;
    z-index: 2;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
}
#helpcrunch-container .helpcrunch-drop-file-images .helpcrunch-file-icon:last-child {
    left: 50%;
    margin-left: 16px;
    color: #2bd699;
    -webkit-transform: rotate(20deg) scale(0.8);
    -ms-transform: rotate(20deg) scale(0.8);
    transform: rotate(20deg) scale(0.8)
}
#helpcrunch-container .helpcrunch-drop-file-button {
    width: 72px;
    height: 72px;
    color: #fff;
    border-radius: 50%;
    position: relative;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.09);
    -webkit-transition: .4s;
    transition: .4s;
    -ms-flex-negative: 0;
    flex-shrink: 0
}
#helpcrunch-container .helpcrunch-drop-file-button svg {
    position: absolute;
    top: 28px;
    left: 24px
}
@media screen and (max-height:540px) {
    #helpcrunch-container .helpcrunch-drop-file h3 {
        padding-top: 30px
    }
    #helpcrunch-container .helpcrunch-drop-file-button {
        width: 50px;
        height: 50px
    }
    #helpcrunch-container .helpcrunch-drop-file-button svg {
        top: 17px;
        left: 13px
    }
}
@media screen and (max-height:490px) {
    #helpcrunch-container .helpcrunch-drop-file h3 {
        padding-top: 20px
    }
    #helpcrunch-container .helpcrunch-drop-file-images {
        height: 64px;
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8)
    }
}
@media screen and (max-height:470px) {
    #helpcrunch-container .helpcrunch-drop-file-button {
        margin-bottom: 20px
    }
    #helpcrunch-container .helpcrunch-drop-file-images {
        display: none
    }
}
#helpcrunch-container .helpcrunch-file-icon {
    position: relative
}
#helpcrunch-container .helpcrunch-file-icon:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 6px;
    left: 0;
    width: 100%;
    height: 90px;
    border-radius: 2px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1)
}
#helpcrunch-container .helpcrunch-file-icon svg {
    vertical-align: top
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-chat-control, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-chat-send-btn, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-chat-control:hover, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-chat-send-btn:hover {
    opacity: 1 !important
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-chat-send-btn-wrap .helpcrunch-chat-send-btn-circle {
    background-color: rgba(243, 243, 243, 0.3) !important
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-emoji-picker-wrapper {
    color: #fff !important
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-emoji-picker-wrapper:hover .helpcrunch-chat-control {
    opacity: .7 !important
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-chat-message-wrapper textarea::-webkit-input-placeholder {
    color: #fff !important
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-chat-message-wrapper textarea:-ms-input-placeholder {
    color: #fff !important
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-chat-message-wrapper textarea::placeholder {
    color: #fff !important
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-footer {
    box-shadow: none;
    color: #fff
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-chat-message-form {
    background-color: transparent;
    box-shadow: none
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-guest .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-load-file-btn-wrap {
    color: #fff
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-header-bottom-img-wrap {
    -webkit-transition: .6s cubic-bezier(0.38, 0.74, 0.24, 1);
    transition: .6s cubic-bezier(0.38, 0.74, 0.24, 1);
    height: 100px;
    opacity: 1
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-header-bottom-img-wrap .helpcrunch-widget-waves {
    height: 45px
}
@media screen and (max-height:580px) {
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-header-bottom-img-wrap {
        height: 50px
    }
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-logo-wrap, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-chat-general-data-wrapper, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-chat-general-data {
    -webkit-transition: -webkit-transform .6s cubic-bezier(0.38, 0.74, 0.24, 1);
    transition: transform .6s cubic-bezier(0.38, 0.74, 0.24, 1)
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-logo-wrap {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    transform: translate(0, 20px)
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-chat-general-data-wrapper {
    -webkit-transform: translate(0, 90px);
    -ms-transform: translate(0, 90px);
    transform: translate(0, 90px)
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-chat-general-data {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    transform: translate(0, -20px)
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-logo-message:before, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-logo-message:after {
    -webkit-transition: -webkit-transform .6s cubic-bezier(0.38, 0.74, 0.24, 1);
    transition: transform .6s cubic-bezier(0.38, 0.74, 0.24, 1);
    -webkit-transform: translate(0, 50px);
    -ms-transform: translate(0, 50px);
    transform: translate(0, 50px)
}

@media screen and (max-height:560px) {
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-chat-general-data-wrapper {
        -webkit-transform: translate(0, 70px);
        -ms-transform: translate(0, 70px);
        transform: translate(0, 70px)
    }
}
@media screen and (max-height:310px) {
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-focus .helpcrunch-chat-general-data-wrapper {
        -webkit-transform: translate(0, 50px);
        -ms-transform: translate(0, 50px);
        transform: translate(0, 50px)
    }
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-logo-wrap .helpcrunch-logo-message, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-logo-wrap .helpcrunch-logo-message {
    margin-top: 6px;
    -webkit-transition: .6s;
    transition: .6s;
    font-size: 14px;
    font-weight: 500
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-logo-wrap .helpcrunch-logo-message:after, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-logo-wrap .helpcrunch-logo-message:after {
    display: none
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-logo-wrap .helpcrunch-logo-message:before, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-logo-wrap .helpcrunch-logo-message:before {
    display: none
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-chat-header {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: height .3s;
    transition: height .3s;
    height: 56px;
    min-height: 56px;
    background: var(--fxo-color);
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap {
    height: 0;
    -webkit-transition: none;
    transition: none
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap .helpcrunch-widget-waves, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap .helpcrunch-widget-waves {
    height: 0;
    top: 0
}
@media screen and (max-height:420px) {
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header {
        height: 60px !important;
        min-height: 60px !important
    }
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header .helpcrunch-all-agents {
        margin-top: 7px
    }
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header .helpcrunch-all-agents .helpcrunch-avatar, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-chat-header .helpcrunch-all-agents .helpcrunch-avatar {
        width: 40px;
        height: 40px;
        line-height: 44px;
        margin: 0 -6px
    }
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header .helpcrunch-all-agents .helpcrunch-avatar img, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-chat-header .helpcrunch-all-agents .helpcrunch-avatar img {
        width: 40px;
        height: 40px
    }
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header .helpcrunch-logo-wrap .helpcrunch-logo, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-chat-header .helpcrunch-logo-wrap .helpcrunch-logo {
        width: 40px;
        height: 40px
    }
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header .helpcrunch-avatar-names-list, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header .helpcrunch-agent-name, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-chat-header .helpcrunch-agent-name, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-header .helpcrunch-logo-message, #helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-chat-header .helpcrunch-logo-message {
        display: none
    }
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-message-form {
    background-color: #f3f5f8
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-send-btn-wrap {
    color: rgba(59, 68, 79, 0.3)
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper {
    cursor: default
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper:hover .helpcrunch-chat-control {
    opacity: .3
}
@media screen and (max-height:360px) {
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-message-form {
        display: none
    }
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-logo-wrap {
    -webkit-transform: translate(0, 15px);
    -ms-transform: translate(0, 15px);
    transform: translate(0, 15px)
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-logo-wrap .helpcrunch-logo {
    height: 50px;
    width: 50px
}
@media screen and (max-height:420px) {
    #helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-logo-wrap {
        -webkit-transform: translate(0, 10px);
        -ms-transform: translate(0, 10px);
        transform: translate(0, 10px)
    }
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-form .helpcrunch-chat-general-data-wrapper {
    display: none
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-all-agents {
    display: block
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-logo-wrap {
    display: none !important;
    -webkit-transform: translate(0, 15px);
    -ms-transform: translate(0, 15px);
    transform: translate(0, 15px)
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-logo-wrap .helpcrunch-logo {
    height: 50px;
    width: 50px
}
#helpcrunch-container .helpcrunch-chat.helpcrunch-state-chat .helpcrunch-chat-general-data-wrapper {
    display: none
}
#helpcrunch-container .helpcrunch-chat-scroll-down {
    position: relative;
    z-index: 5;
    height: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: .24s;
    transition: .24s
}
#helpcrunch-container .helpcrunch-chat-scroll-down button {
    padding: 0;
    outline: 0;
    border: 0;
    position: absolute;
    bottom: 10px;
    left: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-transition: .24s;
    transition: .24s;
    border-radius: 50%;
    color: #666;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
}
#helpcrunch-container .helpcrunch-chat-scroll-down button:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2)
}
#helpcrunch-container .helpcrunch-chat-scroll-down-show {
    visibility: visible;
    opacity: 1
}
#helpcrunch-container .helpcrunch-chat-size-big #helpcrunch-message-container .helpcrunch-message-wrapper {
    max-width: 66%
}
#helpcrunch-container .helpcrunch-chat-size-big #helpcrunch-message-container .helpcrunch-message-wrapper .helpcrunch-message-text {
    max-width: none
}
#helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat-backdrop {
    background-color: rgba(0, 69, 95, 0.2);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 9;
    opacity: 0;
    -webkit-animation: delay-for-backdrop .1s .3s;
    animation: delay-for-backdrop .1s .3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}
#helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat {
    margin: auto;
    max-width: 900px;
    min-width: 900px;
    max-height: 80%;
    min-height: 300px;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    border-radius: 5px;
    opacity: 0
}
#helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-header-bottom-img-wrap {
    display: none !important
}
#helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-day {
    padding-right: 15px;
    padding-left: 15px
}
#helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat .helpcrunch-footer {
    border-radius: 0 0 5px 5px
}
#helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-footer {
    border-radius: 0 0 5px 5px
}
@media screen and (max-width:900px) {
    #helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat {
        max-width: 100%;
        min-width: 100%;
        min-height: 100%;
        border-radius: 0
    }
    #helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat .helpcrunch-footer {
        border-radius: 0
    }
    #helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-day {
        padding-right: 10px;
        padding-left: 10px
    }
}
@media screen and (max-height:736px) {
    #helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat {
        max-height: 90%
    }
}
@media screen and (max-height:680px) {
    #helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat {
        max-height: 100%;
        height: 100%;
        border-radius: 0
    }
    #helpcrunch-container .helpcrunch-chat-size-big .helpcrunch-chat .helpcrunch-footer {
        border-radius: 0
    }
}
@-webkit-keyframes delay-for-backdrop {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}
@keyframes delay-for-backdrop {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}
@-webkit-keyframes dot-pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .9
    }
    30% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 1
    }
    60%, 100% {
        opacity: .9;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}
@keyframes dot-pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .9
    }
    30% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 1
    }
    60%, 100% {
        opacity: .9;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}
#helpcrunch-container #helpcrunch-message-container {
    position: relative
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text {
    max-width: 215px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text img {
    cursor: pointer
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-container {
    margin: 3px 0
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-wrapper {
    max-width: 235px;
    min-width: auto;
    min-height: auto
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-wrapper .helpcrunch-media-link-image .helpcrunch-preview-image-is-loading img {
    display: inline
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-wrapper .helpcrunch-media-link-image .helpcrunch-messages-preloader {
    display: none
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-wrapper .helpcrunch-preview-image-is-loading img {
    display: none
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-wrapper .helpcrunch-preview-image-wrapper .helpcrunch-messages-preloader {
    box-sizing: content-box;
    margin: 0
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-wrapper .helpcrunch-preview-image-wrapper .helpcrunch-messages-preloader:before {
    border-color: #fff
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-wrapper .helpcrunch-preview-image-wrapper img {
    border-radius: 5px 0 5px 5px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-wrapper.helpcrunch-message-agent-wrapper .helpcrunch-preview-image-wrapper .helpcrunch-messages-preloader:before {
    border-color: #3b444f
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-agent+.helpcrunch-message-customer, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-customer+.helpcrunch-message-agent {
    margin-top: 10px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-agent-wrapper {
    position: relative;
    margin-left: 36px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-agent-wrapper .helpcrunch-message {
    border-radius: 0 5px 5px 5px
}
@media screen and (max-height:420px) {
    #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-agent-wrapper {
        max-width: 195px
    }
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tech-wrapper {
    position: relative;
    padding: 0 20px;
    margin: 10px 0;
    display: inline-block;
    max-width: 320px;
    font-size: 12px;
    line-height: 1.17;
    color: #666;
    font-family: "HelveticaNeueCyr", sans-serif;
    opacity: .7
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tech-wrapper b {
    font-weight: 500
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tech-wrapper:before, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tech-wrapper:after {
    content: "\2014";
    font-family: "Times New Roman";
    position: absolute;
    top: calc(50% - 2px);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tech-wrapper:before {
    left: 0
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tech-wrapper:after {
    right: 0
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message {
    display: inline-block;
    vertical-align: top;
    background-color: #fff;
    color: #fff;
    border-radius: 5px 0 5px 5px;
    padding: 10px 10px 7px;
    position: relative;
    white-space: pre-wrap;
    -webkit-transition: opacity .24s;
    transition: opacity .24s
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message:hover .helpcrunch-message-tooltip {
    -webkit-transition: opacity 150ms ease;
    transition: opacity 150ms ease;
    opacity: 1
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-agent-text {
    color: #3b444f
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-agent-author {
    display: block;
    font-family: "HelveticaNeueCyr", sans-serif;
    font-weight: 500;
    text-align: left;
    font-size: 12px;
    margin-right: 6px;
    margin-bottom: 5px;
    color: #0b0f14;
    font-weight: bold;
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message.helpcrunch-right{
    background-color:rgb(51, 126, 255);
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-color-wrapper {
    display: inline-block;
    vertical-align: top;
    border-radius: 0 5px 5px 5px;
    background-color: #fff
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-right {
    float: right
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-left {
    float: left
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-center {
    text-align: center
}
#helpcrunch-container #helpcrunch-message-container .clearFloat {
    clear: both
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-error {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-size: 10px;
    line-height: 10px;
    text-align: right;
    color: #f66;
    margin-top: 5px;
    clear: both
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-error .helpcrunch-try-again {
    cursor: pointer
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-error .helpcrunch-try-again:hover {
    text-decoration: underline
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tooltip {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 26px;
    padding: 6px 10px;
    line-height: 17px;
    margin: auto;
    display: inline-block;
    color: #fff;
    background-color: #6a7385;
    border-radius: 3px;
    text-align: center;
    white-space: nowrap;
    font-size: 10px;
    -webkit-transition: opacity 75ms ease-in-out;
    transition: opacity 75ms ease-in-out
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tooltip.helpcrunch-client {
    right: calc(100% + 10px)
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tooltip.helpcrunch-agent {
    left: calc(100% + 10px)
}
@media screen and (max-height:420px) {
    #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tooltip.helpcrunch-client {
        right: calc(100% + 3px)
    }
    #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-tooltip.helpcrunch-agent {
        left: calc(100% + 3px)
    }
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-avatar-chat {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 9px;
    left: -30px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-avatar-chat img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-edited-message {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: -18px;
    height: 12px;
    color: #aebcbf
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-sticker span {
    display: block
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-sticker span span {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
    font-size: 68px;
    line-height: 68px;
    font-family: Verdana !important
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-spinner-message {
    margin-right: 10px;
    margin-bottom: 10px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-spinner {
    float: left;
    margin: -4px 0 -1px -5px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-spinner:before {
    border-color: #fff
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-spinner-info {
    display: inline-block;
    vertical-align: top;
    margin: 1px 0 0 6px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-spinner-info .helpcrunch-spinner-img {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-size: 14px;
    text-overflow: ellipsis;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-spinner-info .helpcrunch-spinner-size {
    font-family: "HelveticaNeueCyr", sans-serif;
    opacity: .7;
    font-size: 12px;
    text-overflow: ellipsis;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-sending-status-wrap {
    color: #666;
    font-size: 10px;
    line-height: 10px;
    white-space: nowrap;
    margin-top: 5px;
    text-align: right
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-knowledgebase-button, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-message-knowledgebase-button {
    margin: 6px 0 4px 0;
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 30px;
    padding: 0 12px 0 34px;
    letter-spacing: .5px;
    outline: 0;
    opacity: 1;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    -webkit-transition: .24s;
    transition: .24s;
    white-space: nowrap;
    text-transform: uppercase;
    text-decoration: none !important;
    border-radius: 2px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    color: #5184d8;
    border: 1px solid #5184d8;
    background-color: transparent
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-knowledgebase-button:hover, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-message-knowledgebase-button:hover {
    background-color: #5184d8;
    color: #fff
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-knowledgebase-button svg, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-message-knowledgebase-button svg {
    position: absolute;
    top: 50%;
    left: 17px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 20px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-image-wrapper, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-image-wrapper {
    display: inline-block;
    vertical-align: top;
    margin: 3px 0 6px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-image-wrapper img, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-image-wrapper img {
    max-width: 100%;
    cursor: pointer;
    vertical-align: top
}
#helpcrunch-container #helpcrunch-message-container [class*=helpcrunch-media-link], #helpcrunch-container .helpcrunch-notifications-block [class*=helpcrunch-media-link] {
    display: block;
    overflow: hidden
}
#helpcrunch-container #helpcrunch-message-container [class*=helpcrunch-media-link] iframe, #helpcrunch-container .helpcrunch-notifications-block [class*=helpcrunch-media-link] iframe {
    width: 100%;
    height: 150px
}
#helpcrunch-container #helpcrunch-message-container [class*=helpcrunch-media-link] iframe, #helpcrunch-container .helpcrunch-notifications-block [class*=helpcrunch-media-link] iframe, #helpcrunch-container #helpcrunch-message-container .helpcrunch-media-link-image img, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-media-link-image img {
    max-width: 100% !important;
    vertical-align: top;
    margin: 0 !important
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-file-wrapper, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper {
    margin: -5px 0 0 -5px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-file-wrapper .helpcrunch-file-extension, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper .helpcrunch-file-extension {
    border-radius: 3px;
    font-size: 14px;
    letter-spacing: .5px;
    margin: 0 10px -2px 0;
    padding: 15px 9px 12px;
    text-transform: uppercase;
    float: left;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-file-wrapper .helpcrunch-file-extension:hover+.helpcrunch-file-information .helpcrunch-file-name, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper .helpcrunch-file-extension:hover+.helpcrunch-file-information .helpcrunch-file-name {
    text-decoration: underline
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-file-wrapper .helpcrunch-file-extension-client, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper .helpcrunch-file-extension-client {
    background-color: #fff
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-file-wrapper .helpcrunch-file-information-agent, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper .helpcrunch-file-information-agent {
    color: #3b444f
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-file-wrapper .helpcrunch-file-information, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper .helpcrunch-file-information {
    display: inline-block
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-file-wrapper .helpcrunch-file-information p, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper .helpcrunch-file-information p {
    margin-top: 0 !important;
    margin-bottom: -5px !important
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-file-wrapper .helpcrunch-file-information .helpcrunch-file-name, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper .helpcrunch-file-information .helpcrunch-file-name {
    margin-top: 5px;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-file-wrapper .helpcrunch-file-information .helpcrunch-file-name:hover, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper .helpcrunch-file-information .helpcrunch-file-name:hover {
    text-decoration: underline
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-preview-file-wrapper .helpcrunch-file-information .helpcrunch-file-size, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper .helpcrunch-file-information .helpcrunch-file-size {
    margin-top: 4px;
    line-height: 20px;
    opacity: .7
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text {
    display: block;
    font-family: "HelveticaNeueCyr", sans-serif;
    font-size: 14px;
    line-height: 1.4em;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-wrap: break-word
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text a, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text a {
    text-decoration: underline
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text a:hover, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text a:hover {
    text-decoration: none
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text a[style*="background-color"]:hover, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text a[style*="background-color"]:hover {
    opacity: .85
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text span, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text span {
    font-style: normal
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text ul, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text ul, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text ol, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text ol {
    white-space: normal;
    padding-left: 30px;
    margin: 0
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h1, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h1, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h2, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h2, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h3, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h3, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h4, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h4, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h5, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h5, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h6, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h6 {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-transform: none;
    text-align: left;
    padding: 0;
    margin: 0
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h1, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h1 {
    font-size: 22px !important;
    line-height: 26px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h2, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h2 {
    font-size: 20px !important;
    line-height: 24px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h3, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h3 {
    font-size: 18px !important;
    line-height: 22px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h4, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h4 {
    font-size: 16px !important;
    line-height: 20px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h5, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h5 {
    font-size: 14px !important;
    line-height: 18px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text h6, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text h6 {
    font-size: 12px !important;
    line-height: 16px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text pre, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text pre, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text code, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text code {
    font-family: "HelveticaNeueCyr", sans-serif;
    border: 1px solid #adb5bf;
    background-color: #fafafa;
    border-radius: 2px;
    padding: 2px 2px 0;
    color: #000;
    line-height: 20px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text pre .helpcrunch-media-link-image, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text pre .helpcrunch-media-link-image, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text code .helpcrunch-media-link-image, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text code .helpcrunch-media-link-image, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text pre .open-modal-image-preview, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text pre .open-modal-image-preview, #helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text code .open-modal-image-preview, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text code .open-modal-image-preview {
    display: none !important
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text pre, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text pre {
    overflow: auto;
    padding: 5px 5px 1px;
    max-height: 240px
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text pre code, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text pre code {
    padding: 0;
    border-radius: 0;
    border: 0
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text blockquote, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text blockquote {
    margin: 0;
    white-space: normal;
    padding: 3px 0 3px 12px;
    border-left: 3px solid #adb5bf
}
#helpcrunch-container #helpcrunch-message-container .helpcrunch-message-text img, #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-text img {
    max-width: 100% !important;
    height: auto !important;
    vertical-align: bottom;
    margin: 3px 0 6px;
    border-radius: 0
}
#helpcrunch-container .load-more-wrapper .helpcrunch-messages-preloader {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 100%;
    height: 1px
}
#helpcrunch-container .load-more-wrapper .helpcrunch-messages-preloader:before {
    border-radius: 0 0 50% 50%
}
#helpcrunch-container .helpcrunch-messages-preloader {
    display: block;
    width: 44px;
    height: 44px;
    margin: 0 auto 15px auto
}
#helpcrunch-container .helpcrunch-messages-preloader:before {
    content: "";
    display: block;
    position: relative;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border: 3px solid #3b444f;
    border-radius: 50%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-animation: helpcrunch-pulse 1.5s ease-out infinite;
    animation: helpcrunch-pulse 1.5s ease-out infinite
}
@-webkit-keyframes helpcrunch-pulse {
    0%, 5% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1
    }
    20% {
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}
@keyframes helpcrunch-pulse {
    0%, 5% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1
    }
    20% {
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}
#helpcrunch-container .helpcrunch-notifications-block {
    position: absolute;
    bottom: 0;
    margin-bottom: 60px
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-messages-preloader {
    display: none
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-messages-preloader {
    display: none
}
#helpcrunch-container .helpcrunch-notifications-block.animate-notification .helpcrunch-notification-wrapper {
    -webkit-animation: show-notification-wrapper .3s;
    animation: show-notification-wrapper .3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper {
    max-height: calc(100vh - 70px);
    max-width: calc(100vw - 10px);
    cursor: pointer;
    background-color: #fff;
    border-radius: 5px;
    width: 305px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    margin: 5px;
    padding: 15px;
    overflow: hidden;
    position: absolute;
    visibility: visible;
    opacity: 0;
    -webkit-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    bottom: 0
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper:hover .helpcrunch-notification-btn-close {
    color: #1d1d26
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-text {
    font-size: 14px;
    color: #3b444f;
    line-height: 1.4em
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-text>span {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    overflow: hidden
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-text>span .helpcrunch-send-file-text {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 100%
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-text .helpcrunch-message {
    background-color: transparent !important
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-header {
    height: 40px;
    margin-bottom: 0;
    line-height: 40px;
    vertical-align: middle
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-header .helpcrunch-avatar {
    box-shadow: none !important;
    height: 30px !important;
    width: 30px !important;
    line-height: 30px
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-header .helpcrunch-avatar>span {
    vertical-align: middle
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-header .helpcrunch-avatar img {
    width: 30px;
    height: 30px;
    border-radius: 50%
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-header .helpcrunch-agent-name {
    display: inline-block;
    vertical-align: top;
    color: #0b0f14;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .3px;
    height: 20px;
    line-height: 1.43;
    margin-left: 12px;
    margin-top: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 175px
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-btn-close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 16;
    color: transparent;
    opacity: .3;
    -webkit-transition: opacity .24s;
    transition: opacity .24s
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-btn-close:hover {
    opacity: .6
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-btn-close svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: 11px 10px 5px 5px;
    box-sizing: initial !important
}
@media screen and (max-device-width:1024px) {
    #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-btn-close {
        color: #1d1d26
    }
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-text-hiding {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0, #fff 60%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 60%)
}
@media screen and (min-height:480px) {
    #helpcrunch-container .helpcrunch-notifications-block .helpcrunch-notification-wrapper {
        max-height: 400px !important
    }
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper {
    margin: 0
}
#helpcrunch-container .helpcrunch-notifications-block .helpcrunch-preview-file-wrapper .helpcrunch-file-extension {
    margin: 0 10px 0 0
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-button .helpcrunch-notification-wrapper {
    max-height: calc(100vh - 75px)
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-icon .helpcrunch-notifications-block {
    margin-bottom: 65px
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-icon .helpcrunch-notifications-block .helpcrunch-notification-wrapper {
    max-height: calc(100vh - 80px)
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-left .helpcrunch-notifications-block {
    left: -5px
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-left .helpcrunch-notifications-block .helpcrunch-notification-wrapper {
    left: 0
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-right .helpcrunch-notifications-block {
    right: -5px
}
#helpcrunch-container .helpcrunch-status-bar.helpcrunch-right .helpcrunch-notifications-block .helpcrunch-notification-wrapper {
    right: 0
}
@-webkit-keyframes show-notification-wrapper {
    0% {
        opacity: 0;
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px)
    }
    90% {
        opacity: 1
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}
@keyframes show-notification-wrapper {
    0% {
        opacity: 0;
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px)
    }
    90% {
        opacity: 1
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}
#helpcrunch-container #rtl .helpcrunch-status-bar .helpcrunch-button-wrap .helpcrunch-button {
    padding: 17px 60px 15px 26px
}
#helpcrunch-container #rtl .helpcrunch-status-bar .helpcrunch-button-wrap .helpcrunch-button span {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-status-bar.helpcrunch-widget-type-button .helpcrunch-widget-icon, #helpcrunch-container #rtl .helpcrunch-status-bar.helpcrunch-widget-type-tab .helpcrunch-widget-icon {
    right: 12px;
    left: auto
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-logo-wrap .helpcrunch-logo-message {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-general-data-message {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-chat-general-data-wrapper .helpcrunch-chat-online-agents-block .helpcrunch-agent-name {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-btn-minimize {
    right: auto;
    left: 6px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-header .helpcrunch-avatar-names-list {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-end-chat>span {
    margin-right: 10px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-chat-control-wrap {
    right: auto;
    left: 0
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-chat-textarea-wrap {
    float: right;
    margin-left: 0;
    margin-right: 15px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper.helpcrunch-load-file-active:not(.hide-attachments) .helpcrunch-chat-textarea-wrap {
    margin-right: 45px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper textarea {
    text-align: right
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-load-file-btn-wrap {
    left: auto;
    right: 10px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper {
    position: absolute;
    left: 50px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-message-wrapper .helpcrunch-emoji-picker-wrapper .helpcrunch-emoji-picker-box-wrap {
    right: -265px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-message-form .helpcrunch-chat-message-field .helpcrunch-chat-send-btn-wrap {
    float: left;
    margin-left: 5px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-welcome-text {
    direction: rtl;
    text-align: right
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-profile-form form input {
    text-align: right;
    direction: rtl;
    padding-left: 28px;
    padding-right: 20px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-profile-form form input[type=submit] {
    text-align: center
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-profile-form form div .helpcrunch-input-requred {
    right: auto;
    left: 20px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section .checkbox {
    float: right;
    margin-right: 0;
    margin-left: 8px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section .helpcrunch-chat-gdpr-label {
    float: right;
    text-align: right;
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section .helpcrunch-chat-gdpr-label span {
    float: right
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-profile-form form .helpcrunch-chat-gdpr-section .helpcrunch-chat-gdpr-label a {
    margin-left: 0;
    margin-right: 5px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-profile-form form div:after {
    direction: rtl;
    right: auto;
    left: 0;
    border-radius: 0 5px 0 5px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-notifications-block .helpcrunch-notification-text span {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat #helpcrunch-message-container .helpcrunch-message-text span {
    direction: rtl;
    text-align: right
}
#helpcrunch-container #rtl .helpcrunch-chat #helpcrunch-message-container .helpcrunch-message-sending-status-wrap {
    direction: rtl;
    text-align: right
}
#helpcrunch-container #rtl .helpcrunch-chat #helpcrunch-message-container .helpcrunch-message-agent-author {
    direction: rtl;
    text-align: right;
    margin-right: 0;
    magrin-left: 6px
}
#helpcrunch-container #rtl .helpcrunch-chat #helpcrunch-message-container .helpcrunch-message-container.helpcrunch-message-tech .helpcrunch-message-tech-wrapper {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-messages-container{
    /*background: #000;*/
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-waiting-message, #helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-message-notification {
    padding: 20px 60px 16px 10px;
    direction: rtl;
    text-align: right
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-waiting-message svg, #helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-message-notification svg {
    left: auto;
    right: 14px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-text-wrapper .helpcrunch-rating-text {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-chat-messages .helpcrunch-chat-day .helpcrunch-chat-day-title span {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-chat-messages-container .helpcrunch-rating-block .helpcrunch-rating-text {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-messages-preloader {
    left: auto;
    right: 8px
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-footer .helpcrunch-end-chat {
    right: 10px;
    text-align: right;
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-footer .helpcrunch-end-chat+.helpcrunch-chat-powered {
    left: 10px;
    right: auto
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-footer .helpcrunch-end-chat.helpcrunch-end-chat-hidden+.helpcrunch-chat-powered {
    left: auto;
    right: 50%
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-footer .helpcrunch-end-chat-buttons>div {
    direction: rtl
}
#helpcrunch-container #rtl .helpcrunch-chat .helpcrunch-footer .helpcrunch-end-chat-popup-confirm {
    padding: 0 10px
}
#helpcrunch-container #rtl .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-btn-close {
    left: 0;
    right: auto
}
#helpcrunch-container #rtl .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-header .helpcrunch-avatar {
    float: right
}
#helpcrunch-container #rtl .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-header .helpcrunch-agent-name {
    float: right;
    direction: rtl;
    margin-left: 0;
    margin-right: 12px
}
#helpcrunch-container #rtl .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-text, #helpcrunch-container #rtl .helpcrunch-notifications-block .helpcrunch-notification-wrapper .helpcrunch-notification-text-hiding {
    text-align: right;
    direction: rtl
}
#helpcrunch-container .helpcrunch-popup {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 60;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    font-family: "HelveticaNeueCyr", sans-serif;
    -webkit-animation: overflow-delay-hide 2.1s linear;
    animation: overflow-delay-hide 2.1s linear;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    width: 0;
    height: 0;
    visibility: hidden;
    -webkit-transition: width 1ms .8s, height 1ms .8s, visibility .8s;
    transition: width 1ms .8s, height 1ms .8s, visibility .8s
}
#helpcrunch-container .helpcrunch-popup:not(.helpcrunch-popup-show) .helpcrunch-popup-overlay {
    opacity: 0 !important
}
#helpcrunch-container .helpcrunch-popup-show {
    -webkit-transition: none;
    transition: none;
    width: 100%;
    height: 100%;
    visibility: visible;
    -webkit-animation: overflow-delay .9s linear;
    animation: overflow-delay .9s linear
}
#helpcrunch-container .helpcrunch-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: black
}
#helpcrunch-container .helpcrunch-popup-overlay-hide .helpcrunch-popup-overlay {
    opacity: 0 !important
}
#helpcrunch-container .helpcrunch-popup-overlay-click-enabled, #helpcrunch-container .helpcrunch-popup-overlay-click-enabled .helpcrunch-popup-content {
    cursor: pointer
}
#helpcrunch-container .helpcrunch-popup-content {
    margin: 0;
    position: relative;
    z-index: 2;
    max-width: 100%;
    width: 560px;
    opacity: 0
}
#helpcrunch-container .helpcrunch-popup-content-block {
    position: relative;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    margin: 10px;
    cursor: auto
}
#helpcrunch-container .helpcrunch-popup-content-block h1, #helpcrunch-container .helpcrunch-popup-content-block .main-text {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 420px;
    box-sizing: border-box
}
#helpcrunch-container .helpcrunch-popup-content-block h1 {
    font-size: 32px;
    line-height: 1.3;
    font-weight: bold;
    padding-top: 75px
}
#helpcrunch-container .helpcrunch-popup-content-block .main-text {
    font-size: 16px;
    line-height: 1.5;
    padding-top: 20px;
    padding-bottom: 45px;
    min-height: 100px
}
#helpcrunch-container .helpcrunch-popup-content-block .main-text ul, #helpcrunch-container .helpcrunch-popup-content-block .main-text ol {
    text-align: left
}
#helpcrunch-container .helpcrunch-popup .helpcrunch-close-button {
    position: absolute;
    z-index: 5;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    -webkit-transition: .24s;
    transition: .24s
}
#helpcrunch-container .helpcrunch-popup .helpcrunch-close-button:hover {
    opacity: .8
}
#helpcrunch-container .helpcrunch-popup-bottom {
    padding-bottom: 50px
}
#helpcrunch-container .helpcrunch-popup-bottom a {
    display: inline-block;
    vertical-align: top;
    padding: 21px 40px 17px;
    -webkit-transition: .24s;
    transition: .24s;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer
}
#helpcrunch-container .helpcrunch-popup-bottom a svg {
    display: none
}
#helpcrunch-container .helpcrunch-popup-bottom a:hover {
    opacity: .8
}
#helpcrunch-container .helpcrunch-popup .helpcrunch-chat-powered {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 18px;
    padding: 0 20px;
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
    opacity: .8
}
#helpcrunch-container .helpcrunch-popup .helpcrunch-chat-powered:hover {
    opacity: .9
}
#helpcrunch-container .helpcrunch-popup-position-left-top, #helpcrunch-container .helpcrunch-popup-position-left-middle, #helpcrunch-container .helpcrunch-popup-position-left-bottom {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}
#helpcrunch-container .helpcrunch-popup-position-middle-top, #helpcrunch-container .helpcrunch-popup-position-middle-middle, #helpcrunch-container .helpcrunch-popup-position-middle-bottom {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
#helpcrunch-container .helpcrunch-popup-position-right-top, #helpcrunch-container .helpcrunch-popup-position-right-middle, #helpcrunch-container .helpcrunch-popup-position-right-bottom {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}
#helpcrunch-container .helpcrunch-popup-position-left-top, #helpcrunch-container .helpcrunch-popup-position-middle-top, #helpcrunch-container .helpcrunch-popup-position-right-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}
#helpcrunch-container .helpcrunch-popup-position-left-top .helpcrunch-popup-content, #helpcrunch-container .helpcrunch-popup-position-middle-top .helpcrunch-popup-content, #helpcrunch-container .helpcrunch-popup-position-right-top .helpcrunch-popup-content {
    margin-bottom: auto
}
#helpcrunch-container .helpcrunch-popup-position-left-middle, #helpcrunch-container .helpcrunch-popup-position-middle-middle, #helpcrunch-container .helpcrunch-popup-position-right-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}
#helpcrunch-container .helpcrunch-popup-position-left-middle .helpcrunch-popup-content, #helpcrunch-container .helpcrunch-popup-position-middle-middle .helpcrunch-popup-content, #helpcrunch-container .helpcrunch-popup-position-right-middle .helpcrunch-popup-content {
    margin-top: auto;
    margin-bottom: auto
}
#helpcrunch-container .helpcrunch-popup-position-left-bottom, #helpcrunch-container .helpcrunch-popup-position-middle-bottom, #helpcrunch-container .helpcrunch-popup-position-right-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}
#helpcrunch-container .helpcrunch-popup-position-left-bottom .helpcrunch-popup-content, #helpcrunch-container .helpcrunch-popup-position-middle-bottom .helpcrunch-popup-content, #helpcrunch-container .helpcrunch-popup-position-right-bottom .helpcrunch-popup-content {
    margin-top: auto
}
@media screen and (max-width:500px) {
    #helpcrunch-container .helpcrunch-popup-content-block h1 {
        padding-top: 60px
    }
    #helpcrunch-container .helpcrunch-popup-content-block p {
        padding-top: 16px;
        padding-bottom: 40px;
        min-height: 80px
    }
    #helpcrunch-container .helpcrunch-popup-bottom a {
        padding: 19px 36px 16px
    }
}
@-webkit-keyframes overflow-delay {
    0%, 99.9% {
        overflow-y: hidden
    }
    100% {
        overflow-y: auto
    }
}
@keyframes overflow-delay {
    0%, 99.9% {
        overflow-y: hidden
    }
    100% {
        overflow-y: auto
    }
}
@-webkit-keyframes overflow-delay-hide {
    0%, 99.9% {
        overflow-y: hidden
    }
    100% {
        overflow-y: auto
    }
}
@keyframes overflow-delay-hide {
    0%, 99.9% {
        overflow-y: hidden
    }
    100% {
        overflow-y: auto
    }
}
#helpcrunch-container .helpcrunch-popup .helpcrunch-popup-content {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}
#helpcrunch-container .helpcrunch-popup .helpcrunch-popup-overlay {
    -webkit-transition: .5s .3s;
    transition: .5s .3s
}
#helpcrunch-container .helpcrunch-popup.helpcrunch-popup-show .helpcrunch-popup-content {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}
#helpcrunch-container .helpcrunch-popup.helpcrunch-popup-show .helpcrunch-popup-overlay {
    -webkit-transition: .5s;
    transition: .5s
}
#helpcrunch-container .helpcrunch-popup-animation-none .helpcrunch-popup-content {
    opacity: 1
}
#helpcrunch-container .helpcrunch-popup-animation-none .helpcrunch-popup-overlay {
    -webkit-transition: none !important;
    transition: none !important
}
#helpcrunch-container .helpcrunch-popup-animation-fade-in .helpcrunch-popup-content {
    -webkit-animation-name: popup-animation-fade-in-hide;
    animation-name: popup-animation-fade-in-hide
}
#helpcrunch-container .helpcrunch-popup-animation-fade-in.helpcrunch-popup-show .helpcrunch-popup-content {
    -webkit-animation-name: popup-animation-fade-in;
    animation-name: popup-animation-fade-in
}
@-webkit-keyframes popup-animation-fade-in {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}
@keyframes popup-animation-fade-in {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}
@-webkit-keyframes popup-animation-fade-in-hide {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}
@keyframes popup-animation-fade-in-hide {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}
#helpcrunch-container .helpcrunch-popup-animation-zoom-in .helpcrunch-popup-content {
    -webkit-transform: scale(0.2);
    -ms-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-animation-name: popup-animation-zoom-in-hide;
    animation-name: popup-animation-zoom-in-hide;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease
}
#helpcrunch-container .helpcrunch-popup-animation-zoom-in.helpcrunch-popup-show .helpcrunch-popup-content {
    -webkit-animation-name: popup-animation-zoom-in;
    animation-name: popup-animation-zoom-in;
    -webkit-animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04);
    animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04)
}
@-webkit-keyframes popup-animation-zoom-in {
    0% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
        opacity: 0
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}
@keyframes popup-animation-zoom-in {
    0% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
        opacity: 0
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}
@-webkit-keyframes popup-animation-zoom-in-hide {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    100% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
        opacity: 0
    }
}
@keyframes popup-animation-zoom-in-hide {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    100% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
        opacity: 0
    }
}
#helpcrunch-container .helpcrunch-popup-animation-zoom-out .helpcrunch-popup-content {
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
    -webkit-animation-name: popup-animation-zoom-out-hide;
    animation-name: popup-animation-zoom-out-hide;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease
}
#helpcrunch-container .helpcrunch-popup-animation-zoom-out.helpcrunch-popup-show .helpcrunch-popup-content {
    -webkit-animation-name: popup-animation-zoom-out;
    animation-name: popup-animation-zoom-out;
    -webkit-animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04);
    animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04)
}
@-webkit-keyframes popup-animation-zoom-out {
    0% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        opacity: 0
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}
@keyframes popup-animation-zoom-out {
    0% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        opacity: 0
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}
@-webkit-keyframes popup-animation-zoom-out-hide {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        opacity: 0
    }
}
@keyframes popup-animation-zoom-out-hide {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        opacity: 0
    }
}
#helpcrunch-container .helpcrunch-popup-animation-slide-in .helpcrunch-popup-content {
    -webkit-animation-name: popup-animation-slide-in-hide;
    animation-name: popup-animation-slide-in-hide
}
#helpcrunch-container .helpcrunch-popup-animation-slide-in.helpcrunch-popup-show .helpcrunch-popup-content {
    -webkit-animation-name: popup-animation-slide-in;
    animation-name: popup-animation-slide-in
}
@-webkit-keyframes popup-animation-slide-in {
    0% {
        -webkit-transform: translate3d(0, 30px, 0);
        transform: translate3d(0, 30px, 0);
        opacity: 0
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}
@keyframes popup-animation-slide-in {
    0% {
        -webkit-transform: translate3d(0, 30px, 0);
        transform: translate3d(0, 30px, 0);
        opacity: 0
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}
@-webkit-keyframes popup-animation-slide-in-hide {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
    100% {
        -webkit-transform: translate3d(0, 30px, 0);
        transform: translate3d(0, 30px, 0);
        opacity: 0
    }
}
@keyframes popup-animation-slide-in-hide {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
    100% {
        -webkit-transform: translate3d(0, 30px, 0);
        transform: translate3d(0, 30px, 0);
        opacity: 0
    }
}
#helpcrunch-container .helpcrunch-popup-animation-fly-in-from-top .helpcrunch-popup-content {
    bottom: 100%;
    -webkit-animation-name: popup-animation-fly-in-from-top-hide;
    animation-name: popup-animation-fly-in-from-top-hide;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease
}
#helpcrunch-container .helpcrunch-popup-animation-fly-in-from-top.helpcrunch-popup-show .helpcrunch-popup-content {
    -webkit-animation-name: popup-animation-fly-in-from-top;
    animation-name: popup-animation-fly-in-from-top;
    -webkit-animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04);
    animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04)
}
@-webkit-keyframes popup-animation-fly-in-from-top {
    0% {
        bottom: 100%;
        opacity: 1
    }
    100% {
        bottom: 0;
        opacity: 1
    }
}
@keyframes popup-animation-fly-in-from-top {
    0% {
        bottom: 100%;
        opacity: 1
    }
    100% {
        bottom: 0;
        opacity: 1
    }
}
@-webkit-keyframes popup-animation-fly-in-from-top-hide {
    0% {
        bottom: 0;
        opacity: 1
    }
    100% {
        bottom: 100%;
        opacity: 1
    }
}
@keyframes popup-animation-fly-in-from-top-hide {
    0% {
        bottom: 0;
        opacity: 1
    }
    100% {
        bottom: 100%;
        opacity: 1
    }
}
#helpcrunch-container .helpcrunch-popup-animation-fly-in-from-left .helpcrunch-popup-content {
    right: 100%;
    -webkit-animation-name: popup-animation-fly-in-from-left-hide;
    animation-name: popup-animation-fly-in-from-left-hide;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease
}
#helpcrunch-container .helpcrunch-popup-animation-fly-in-from-left.helpcrunch-popup-show .helpcrunch-popup-content {
    -webkit-animation-name: popup-animation-fly-in-from-left;
    animation-name: popup-animation-fly-in-from-left;
    -webkit-animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04);
    animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04)
}
@-webkit-keyframes popup-animation-fly-in-from-left {
    0% {
        right: 100%;
        opacity: 1
    }
    100% {
        right: 0;
        opacity: 1
    }
}
@keyframes popup-animation-fly-in-from-left {
    0% {
        right: 100%;
        opacity: 1
    }
    100% {
        right: 0;
        opacity: 1
    }
}
@-webkit-keyframes popup-animation-fly-in-from-left-hide {
    0% {
        right: 0;
        opacity: 1
    }
    100% {
        right: 100%;
        opacity: 1
    }
}
@keyframes popup-animation-fly-in-from-left-hide {
    0% {
        right: 0;
        opacity: 1
    }
    100% {
        right: 100%;
        opacity: 1
    }
}
#helpcrunch-container .helpcrunch-popup-animation-fly-in-from-right .helpcrunch-popup-content {
    left: 100%;
    -webkit-animation-name: popup-animation-fly-in-from-right-hide;
    animation-name: popup-animation-fly-in-from-right-hide;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease
}
#helpcrunch-container .helpcrunch-popup-animation-fly-in-from-right.helpcrunch-popup-show .helpcrunch-popup-content {
    -webkit-animation-name: popup-animation-fly-in-from-right;
    animation-name: popup-animation-fly-in-from-right;
    -webkit-animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04);
    animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04)
}
@-webkit-keyframes popup-animation-fly-in-from-right {
    0% {
        left: 100%;
        opacity: 1
    }
    100% {
        left: 0;
        opacity: 1
    }
}
@keyframes popup-animation-fly-in-from-right {
    0% {
        left: 100%;
        opacity: 1
    }
    100% {
        left: 0;
        opacity: 1
    }
}
@-webkit-keyframes popup-animation-fly-in-from-right-hide {
    0% {
        left: 0;
        opacity: 1
    }
    100% {
        left: 100%;
        opacity: 1
    }
}
@keyframes popup-animation-fly-in-from-right-hide {
    0% {
        left: 0;
        opacity: 1
    }
    100% {
        left: 100%;
        opacity: 1
    }
}
#helpcrunch-container .helpcrunch-popup-animation-fly-in-from-bottom .helpcrunch-popup-content {
    top: 100%;
    -webkit-animation-name: popup-animation-fly-in-from-bottom-hide;
    animation-name: popup-animation-fly-in-from-bottom-hide;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease
}
#helpcrunch-container .helpcrunch-popup-animation-fly-in-from-bottom.helpcrunch-popup-show .helpcrunch-popup-content {
    -webkit-animation-name: popup-animation-fly-in-from-bottom;
    animation-name: popup-animation-fly-in-from-bottom;
    -webkit-animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04);
    animation-timing-function: cubic-bezier(0.07, 0.94, 0.4, 1.04)
}
@-webkit-keyframes popup-animation-fly-in-from-bottom {
    0% {
        top: 100%;
        opacity: 1
    }
    100% {
        top: 0;
        opacity: 1
    }
}
@keyframes popup-animation-fly-in-from-bottom {
    0% {
        top: 100%;
        opacity: 1
    }
    100% {
        top: 0;
        opacity: 1
    }
}
@-webkit-keyframes popup-animation-fly-in-from-bottom-hide {
    0% {
        top: 0;
        opacity: 1
    }
    100% {
        top: 100%;
        opacity: 1
    }
}
@keyframes popup-animation-fly-in-from-bottom-hide {
    0% {
        top: 0;
        opacity: 1
    }
    100% {
        top: 100%;
        opacity: 1
    }
}
#helpcrunch-container .helpcrunch-popup-template-big-button .helpcrunch-close-button, #helpcrunch-container .helpcrunch-popup-template-colored .helpcrunch-close-button {
    top: 17px;
    right: 17px;
    width: 20px;
    height: 20px
}
#helpcrunch-container .helpcrunch-popup-template-big-button h1, #helpcrunch-container .helpcrunch-popup-template-colored h1 {
    text-transform: uppercase
}
#helpcrunch-container .helpcrunch-popup-template-big-button .helpcrunch-chat-powered, #helpcrunch-container .helpcrunch-popup-template-split-up .helpcrunch-chat-powered, #helpcrunch-container .helpcrunch-popup-template-left-aligned .helpcrunch-chat-powered, #helpcrunch-container .helpcrunch-popup-template-rounded .helpcrunch-chat-powered {
    bottom: auto;
    top: 24px
}
#helpcrunch-container .helpcrunch-popup-template-left-aligned .helpcrunch-popup-bottom, #helpcrunch-container .helpcrunch-popup-template-rounded .helpcrunch-popup-bottom {
    max-width: 420px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box
}
#helpcrunch-container .helpcrunch-popup-template-simple .helpcrunch-popup-content-block {
    border-radius: 3px
}
#helpcrunch-container .helpcrunch-popup-template-simple .helpcrunch-popup-bottom a {
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14)
}
#helpcrunch-container .helpcrunch-popup-template-big-button h1 {
    font-size: 22px
}
#helpcrunch-container .helpcrunch-popup-template-big-button .helpcrunch-popup-bottom {
    padding-bottom: 0
}
#helpcrunch-container .helpcrunch-popup-template-big-button .helpcrunch-popup-bottom a {
    display: block;
    letter-spacing: 2px;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 29px 0 25px
}
#helpcrunch-container .helpcrunch-popup-template-colored .helpcrunch-popup-content-block {
    border-radius: 2px
}
#helpcrunch-container .helpcrunch-popup-template-colored h1 {
    font-size: 26px
}
#helpcrunch-container .helpcrunch-popup-template-colored .helpcrunch-popup-bottom {
    padding-left: 20px;
    padding-right: 20px
}
#helpcrunch-container .helpcrunch-popup-template-colored .helpcrunch-popup-bottom a {
    width: 100%;
    max-width: 380px
}
#helpcrunch-container .helpcrunch-popup-template-split-up .helpcrunch-popup-content-block {
    border-radius: 10px
}
#helpcrunch-container .helpcrunch-popup-template-split-up h1 {
    font-size: 28px
}
#helpcrunch-container .helpcrunch-popup-template-split-up .helpcrunch-popup-bottom {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    padding-top: 20px;
    padding-bottom: 20px
}
#helpcrunch-container .helpcrunch-popup-template-split-up .helpcrunch-popup-bottom a {
    border-radius: 3px;
    padding: 16px 34px 12px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.14)
}
#helpcrunch-container .helpcrunch-popup-template-left-aligned .helpcrunch-popup-content-block {
    text-align: left;
    border-radius: 1px
}
#helpcrunch-container .helpcrunch-popup-template-left-aligned h1 {
    font-size: 26px;
    font-weight: normal;
    text-transform: uppercase
}
#helpcrunch-container .helpcrunch-popup-template-left-aligned .helpcrunch-popup-bottom a {
    border-radius: 100px;
    border-width: 1px;
    border-style: solid;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    padding: 20px 26px 16px 40px
}
#helpcrunch-container .helpcrunch-popup-template-left-aligned .helpcrunch-popup-bottom a svg {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 38px;
    margin-left: 18px
}
#helpcrunch-container .helpcrunch-popup-template-rounded .helpcrunch-popup-content-block {
    text-align: left;
    border-radius: 10px
}
#helpcrunch-container .helpcrunch-popup-template-rounded h1 {
    font-weight: normal
}
#helpcrunch-container .helpcrunch-popup-template-rounded p {
    font-size: 17px
}
#helpcrunch-container .helpcrunch-popup-template-rounded .helpcrunch-popup-bottom a {
    border-radius: 100px;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 14px
}
@media screen and (max-width:500px) {
    #helpcrunch-container .helpcrunch-popup-template-simple h1, #helpcrunch-container .helpcrunch-popup-template-colored h1, #helpcrunch-container .helpcrunch-popup-template-big-button h1, #helpcrunch-container .helpcrunch-popup-template-split-up h1, #helpcrunch-container .helpcrunch-popup-template-left-aligned h1, #helpcrunch-container .helpcrunch-popup-template-rounded h1 {
        font-size: 22px
    }
    #helpcrunch-container .helpcrunch-popup-template-simple p, #helpcrunch-container .helpcrunch-popup-template-colored p, #helpcrunch-container .helpcrunch-popup-template-big-button p, #helpcrunch-container .helpcrunch-popup-template-split-up p, #helpcrunch-container .helpcrunch-popup-template-left-aligned p, #helpcrunch-container .helpcrunch-popup-template-rounded p {
        font-size: 15px
    }
    #helpcrunch-container .helpcrunch-popup-template-simple h1 {
        font-size: 26px
    }
    #helpcrunch-container .helpcrunch-popup-template-simple .helpcrunch-popup-bottom a {
        font-size: 15px
    }
    #helpcrunch-container .helpcrunch-popup-template-big-button h1 {
        font-size: 20px
    }
    #helpcrunch-container .helpcrunch-popup-template-big-button .helpcrunch-popup-bottom a {
        font-size: 13px;
        padding: 24px 0 20px
    }
    #helpcrunch-container .helpcrunch-popup-template-colored .helpcrunch-popup-bottom a {
        font-size: 15px;
        padding: 16px 36px 14px
    }
    #helpcrunch-container .helpcrunch-popup-template-split-up .helpcrunch-popup-bottom {
        padding-top: 16px;
        padding-bottom: 16px
    }
    #helpcrunch-container .helpcrunch-popup-template-split-up .helpcrunch-popup-bottom a {
        font-size: 15px;
        padding: 14px 30px 10px
    }
    #helpcrunch-container .helpcrunch-popup-template-left-aligned .helpcrunch-popup-bottom a {
        font-size: 13px;
        padding: 17px 23px 13px 35px
    }
    #helpcrunch-container .helpcrunch-popup-template-rounded h1 {
        font-size: 26px
    }
    #helpcrunch-container .helpcrunch-popup-template-rounded .helpcrunch-popup-bottom a {
        padding: 17px 30px 14px
    }
}
#helpcrunch-container .helpcrunch-info-notification {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    visibility: visible;
    max-height: 30px;
    -webkit-transition: max-height .24s, visibility 1ms;
    transition: max-height .24s, visibility 1ms;
    text-align: center
}
#helpcrunch-container .helpcrunch-info-notification>span {
    display: inline-block;
    vertical-align: top;
    padding: 4px 0 3px;
    color: #3b444f;
    font-size: 14px;
    text-align: center
}
#helpcrunch-container .helpcrunch-info-notification-hidden {
    -webkit-transition: .24s;
    transition: .24s;
    max-height: 0;
    visibility: hidden
}
@-webkit-keyframes show-modal-dialog {
    from {
        opacity: 0;
        display: -webkit-box;
        display: flex
    }
    to {
        opacity: 1;
        display: -webkit-box;
        display: flex
    }
}
@keyframes show-modal-dialog {
    from {
        opacity: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
    to {
        opacity: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}
#helpcrunch-container .answer-btn{
    background: linear-gradient(30deg, #edba4f,#ec8a60);
    /*background: #ea9938;*/
    color: #fff;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 50px;
    margin: 2px 0;
    white-space: nowrap;
    display: inline-block;
}
.btns-wrap:after{content:"";display:table;clear:both}