body {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  max-width: 100vw;
  overflow-x: hidden;

  @include desktop {
    padding-top: 96px;

    &.ie-alert-visible {
      padding-top: 262px;
    }
  }

  @include tablet {
    &.ie-alert-visible {
      padding-top: 262px;
    }
  }
}

.acc-container {
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;

  @include desktop {
    max-width: 1170px;
  }

  @include tablet1 {
    padding-left: 31px;
    padding-right: 31px;
  }

  @include tablet2 {
    padding-left: 38px;
    padding-right: 38px;
  }

  @include mobile {
    padding-left: 33px;
    padding-right: 33px;
  }  
}

.acc-section__header {
  display: flex;
  align-items: center;
  margin-bottom: 60px;

  @include desktop {
    margin-bottom: 80px;
  }

  &__icon {
    width: 70px;
    height: 70px;
    margin-right: 30px;
  }

  &__text {
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0.04em;
    color: #2E3139;

    span {
      color: #4F8AF1;
    }

    @include mobile {
      font-size: 36px;
    }
  }
}

.acc-caption {
  font-weight: 900;
  font-size: 56px;
  line-height: 67px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $white;
  margin-top: 15px;

  span {
    color: #2E3139;
  } 
}

.button {
  align-items: center;
  border-radius: 2px;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  letter-spacing: 0.02em;
  line-height: 20px;
  padding: 0 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &--blue {
    color: $blue-invert;
    background-color: $blue;

    &:hover {
      background-color: $blue-hover;
    }
  }

  &--outlined {
    color: $blue-invert;
    background-color: transparent;
    border: 3px solid $blue-invert;

    &:hover {
      background-color: $blue-hover;
    }    
  }

  &--inverted-blue {
    color: $blue;
    background-color: $blue-invert;
    border: 1px solid $blue-invert;

    &:hover {
      color: $blue-invert;
      background-color: $blue;
      border: 1px solid $blue;
    }
  }

  &--orange {
    color: $orange-invert;
    background-color: $orange;

    &:hover {
      background-color: $orange-hover;
    }
  }

  &:disabled {
    color: $white;
    background-color: $gray;
    cursor: inherit;
  }
}

.button-new {
  display: flex;
  width: 236px;
  height: 55px;
  color: $white;
  background-color: $blue-main;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    background-color: $blue-hover;
  }

  &--orange {
    color: $white;
    background-color: $orange;

    &:hover {
      background-color: $orange-hover;
    }
  }
}

.non-underlined {
  text-decoration: none;
}

.acc-dropdown {
  &:focus .acc-dropdown__content,
  &:hover .acc-dropdown__content,
  &:active .acc-dropdown__content,
  .acc-dropdown__activator:focus + .acc-dropdown__content,
  .acc-dropdown__activator:hover + .acc-dropdown__content,
  .acc-dropdown__activator:active + .acc-dropdown__content {
    display: block;
  }

  &__activator {
    cursor: default;
  }

  &__content {
    display: none;
    position: absolute;
    top: 32px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    min-width: 140px;
    padding: 8px 16px;
    margin-left: -16px;
    background-color: $white;
    z-index: 11;

    &--long {
      min-width: 180px;
    }

    &__item {
      padding: 10px 0;
      white-space: nowrap;
    }
  }

  &--up {
    .acc-dropdown__content {
      top: auto;
      bottom: 46px;
    }
  }
}

/* old */
.container {
  position: relative;
  margin: 0 auto;
  max-width: 1170px;
  width: calc(100% - 40px);
  padding: 0 20px;

  @include tablet {
    max-width: 100%;
    width: calc(100% - 80px);
    padding: 0 40px;
  }

  @include mobile {
    max-width: 100%;
    width: calc(100% - 40px);
    padding: 0 20px;
  }  
}

.columns {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  // -webkit-box-shadow: inset 0px 0px 9px -3px rgba(0,0,0,0.75);
  // -moz-box-shadow: inset 0px 0px 9px -3px rgba(0,0,0,0.75);
  // box-shadow: inset 0px 0px 9px -3px rgba(0,0,0,0.75);
  box-sizing: border-box;

  &-1-2 {
    width: 50%;
  }

  &-1-3 {
    width: 33.333%
  }

  &-2-12 {
    width: 16.666%
  }

  &-5-12 {
    width: 41.666%
  }

  &-m-1 {
    @include mobile {
      width: 100%;
    }
  }

  &-t {
    @include tablet {
      &-hide {
        display: none;
      }

      &-6-12 {
        width: 50%;
      }
    }
  }
}

.content {
  font-size: 17px;

  @include mobile {
    margin-top: 20px;
  }  
}

.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.primary {
  color: $blue;
}

.has-text {
  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }  

  &-centered {
    text-align: center;
  }

  &-weight-semibold {
    font-weight: 600;
  }

  &-primary {
    color: $blue;
  }

  &-grey {
    color: #7a7a7a;
  }
}

.is-mobile-hide {
  @include mobile {
    display: none !important;
  }
}

.is-tablet-hide {
  @include tablet {
    display: none !important;
  }
}

.is-desktop-hide {
  @include desktop {
    display: none !important;
  }
}

.is-mobile-block {
  display: none !important;

  @include mobile {
    display: block !important;
  }
}

.is-mobile-flex {
  display: none !important;

  @include mobile {
    display: flex !important;
  }
}

.is-tablet-block {
  display: none;

  @include tablet {
    display: block;
  }
}

.is-desktop-block {
  display: none;

  @include desktop {
    display: block;
  }
}

.is-block {
  display: block;
}

.is-inline-block {
  display: inline-block;
}

.is-hidden {
  display: none;
}

.is-inline-flex {
  display: inline-flex;
}

.vertical-center-flex {
	align-items: center;
}

.vertical-top-flex {
  align-items: flex-start;
}

.vertical-bottom-flex {
  align-items: flex-end;
}

.horizontal-center-flex {
  justify-content: center;
}

.line--fw {
  width: 100%;
  height: 1px;
  border: 0;
  margin: 0;
  background-color: #e5e5e5;
}

//.swiper-button {
//  &-prev {
//    background-image: url('../images/slider-arrow-left@1x.png') !important;
//
//    &:hover {
//      background-image: url('../images/slider-arrow-hover-left@1x.png') !important;
//    }
//  }
//
//  &-next {
//    background-image: url('../images/slider-arrow-right@1x.png') !important;
//
//    &:hover {
//      background-image: url('../images/slider-arrow-hover-right@1x.png') !important;
//    }
//  }
//}

// temporary (for secondary pages mocks)
.placeholder {
  text-align: center;
  margin-top: 25vh;
  margin-bottom: 25vh;
}

// headers
.subheader {
  font-size: 24px;
  font-weight: bold;
}

.section__header {
  line-height: 0.8;
  display: inline;
  color: #38373d;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 0.6;
  background-image: linear-gradient(120deg, $blue 0%, $blue 100%);
  background-repeat: no-repeat;
  background-size: 100% 4px;
  background-position: 0 82%;
  transition: background-size 0.25s ease-in;

  @include tablet {
    line-height: 1.5;
    font-size: 42px;
    background-size: 100% 6px;
    background-position: 0 84%;
  }

  @include desktop {
    line-height: 0.8;
    font-size: 48px;
    background-size: 100% 8px;
    background-position: 0 86%;
  }      

  &::after {
    content: '.';
    color: $blue;
    background-image: linear-gradient(120deg, #ffffff 0%, #ffffff 100%);
    font-size: 36px;
    background-size: 10px;

    @include tablet {
      font-size: 52px;
    }

    @include desktop {
      font-size: 60px;
    }
  }
}

.font-digits {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

.ie-alert {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1003;
  height: 120px;
  overflow: hidden;
  text-align: center;
  box-sizing: border-box;
  padding: 16px;
  background-color: #cccccc;

  .ie8 & {
    display: block;
  }

  @include mobile {
    height: 100%;
    bottom: 0;
    padding-top: 25%;
  }

  &__icon,
  &__text {
    display: inline-block;
    vertical-align: middle;
  }

  &__text {
    text-align: left;
    padding-left: 18px;

    &-header {
      color: #c62828;
      font-size: 26px;
      font-weight: bold;
    }

    &-desc {
      font-size: 18px;
    }
  }
}

.warning-modal .tingle-modal-box {
  max-width: 480px;
}

.cursor-pointer {
  cursor: pointer;
}

.acc-modal__container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.35);
  overflow: auto;
  -webkit-transition: 0.5s;
  transition: all 0.3s linear;
}

.acc-modal__window {
  background-color: #fefefe;
  margin: auto;
  width: 100%;

  @include desktop {
    width: 1170px;
  }
  
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 38px 0 30px;
    height: 97px;

    @include tablet1 {
      height: 83px;
    }

    @include tablet2 {
      height: 64px;
    }

    @include mobile {
      height: 48px;
      padding: 0 32px;
    }
  }

  &__number {
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    color: #4B4B4B;
    margin-right: 15px;
  }

  &__title {
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #4F8AF1;
  }
}

.is-hidden {
  display: none;
}

.is-nonvisible {
  opacity: 0;
}

.acc-modal__window__close {
  color: #4F8AF1;
  font-size: 62px;
  font-weight: lighter;

  &:hover,
  &:focus {
    color: #CDDFFF;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
  }
}

.aligned-list {
  padding: 0 0 0 16px;
  margin: 0;
}

/* Accolend video player theme */
$primary-foreground-color: #4F8AF1;
$primary-background-color: #ffffff;
$slider-bg-color: #CDDFFF;

.vjs-default-skin .vjs-big-play-button {
  font-size: 6em;
  line-height: 96px;
  height: 96px;
  width: 96px;
  border-radius: 48px;
  left: 50%;
  top: 50%;
  margin-left: -48px;
  margin-top: -48px;

  @include mobile {
    font-size: 3.8em;
    line-height: 56px;
    height: 56px;
    width: 56px;
    border-radius: 28px;
    left: 50%;
    top: 50%;
    margin-left: -28px;
    margin-top: -28px;    
  }
}

.video-js {
  font-size: 10px;
  color: $primary-foreground-color;

  .vjs-control-bar {
    padding: 33px 0;
    height: 97px;

    @include tablet1 {
      padding: 27px 0;
      height: 83px;
    }

    @include tablet2 {
      padding: 18px 0;
      height: 64px;
    }

    @include mobile {
      padding: 10px 0;
      height: 48px;
    }    
  }

  .vjs-control-bar,
  .vjs-big-play-button,
  .vjs-menu-button .vjs-menu-content {
    background-color: $primary-background-color;
  }

  .vjs-volume-level,
  .vjs-play-progress,
  .vjs-slider-bar {
    background: $primary-foreground-color;
  }

  .vjs-slider {
    background-color: $slider-bg-color;
    background-color: rgba($slider-bg-color, 0.5);
  }

  .vjs-load-progress {
    background: lighten($slider-bg-color, 25%);
    background: rgba($slider-bg-color, 0.5);
  }

  .vjs-load-progress div {
    background: lighten($slider-bg-color, 50%);
    background: rgba($slider-bg-color, 0.75);
  }

  .vjs-tech {
    outline: 0;
  }
}
/* / Accolend video player theme */

.yotpo-review-date {
  display: none !important;
}

.grecaptcha-badge { visibility: hidden; }