.apply-for-loan {
  &__content {
    margin: 10% auto 15% auto;
    width: 100%;

    @include tablet {
      margin: 10% auto 15% auto;
      width: 100%;
    }

    @include desktop {
      margin: 10% auto 15% auto;
      width: 84%;
    }

    @include mobile {
      .column-1-3 {
        display: block;
        width: 100%;
      }
    }
  }

  &__header {
    margin-bottom: 40px;
  }

  &__button {
    width: 260px;
    height: 52px;
    line-height: 52px;
    font-size: 22px;
    margin-bottom: 15px;

    @include tablet {
      width: 220px;
      height: 52px;
      line-height: 52px;
      font-size: 18px;
    }

    @include desktop {
      width: 260px;
      height: 52px;
      line-height: 52px;
      font-size: 22px;
    }
  }
}