
.acc-realtor {

  &__hero {
    background: url('../images/realtor/realtor-hero-bg.jpg') center bottom no-repeat;
    background-size: cover;

    &__content {
      padding: 67px 0 90px 0;

      @include mobile {
        padding: 34px 0;
      }
    }

    .acc-caption {
      font-size: 80px;
      line-height: 88px;
      font-weight: 600;
      margin-bottom: 40px;
      margin-top: 0;

      @include mobile {
        font-size: 45px;
        line-height: 54px;
      }
    }
  }

  &__info-success {
    background-color: $gray-background;
    padding: 64px 0 44px 0;

    &__item {
      display: flex;
      gap: 50px;

      @include up-to-tablet2 {
        flex-direction: column;
        gap: 22px;
      }

      &__img {
        border-radius: 12px;

        @include mobile {
          width: 100%;
        }

        @include tablet1 {
          width: 100%;
        }

        &__container {
          display: flex;
          justify-content: center;
          flex: 0 0 550px;

          @include up-to-tablet2 {
            flex: unset;
          }
        }
      }

      &__container {
        display: flex;
        flex-direction: column;

        @include up-to-tablet2 {
          padding-top: 0;
        }
      }

      &__title {
        font-size: 48px;
        line-height: 67px;
        font-weight: 600;
        margin-bottom: 22px;

        @include mobile {
          font-size: 35px;
          line-height: 45px;
        }
      }

      &__text {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 16px;
      }

      &__benefits {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__item {
          display: flex;
          align-items: center;
          gap: 12px;
          background-color: $white;
          padding: 12px 14px;
          border-radius: 10px;

          &__text {
            font-size: 18px;
            line-height: 25px;
            font-weight: 600;
          }
        }
      }
    }
  }

  &__info-repeat {
    background-color: $white;
    padding: 64px 0;

    &__item {
      display: flex;
      gap: 50px;
      flex-direction: row-reverse;
      align-items: center;

      @include up-to-tablet2 {
        flex-direction: column;
        gap: 22px;
      }

      &__img {
        @include mobile {
          width: 100%;
        }

        @include tablet1 {
          width: 100%;
        }

        &__container {
          display: flex;
          justify-content: center;
          flex: 0 0 550px;

          @include up-to-tablet2 {
            flex: unset;
          }
        }
      }

      &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include up-to-tablet2 {
          padding-top: 0;
          gap: 21px;
        }
      }

      &__title {
        font-size: 48px;
        line-height: 67px;
        font-weight: 600;

        @include mobile {
          font-size: 35px;
          line-height: 45px;
        }
      }

      &__text {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  &__videos {
    background-color: $gray-background;
    padding: 40px 0;

    &__heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 22px;
      margin-bottom: 50px;
      text-align: center;

      &__title {
        font-size: 48px;
        line-height: 67px;
        font-weight: 600;

        @include mobile {
          font-size: 35px;
          line-height: 45px;
        }
      }

      &__desc {
        font-size: 18px;
        line-height: 25px;
        max-width: 580px;
      }
    }

    .acc-educational__video {
      justify-content: center;
    }

    .acc-educational__video__container {
      @media (max-width: 400px) {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__connect {
    background-color: $white;
    padding: 60px 0;

    &__container {
      display: flex;
      flex-direction: row-reverse;
      background-color: $gray-background;
      border-radius: 24px;

      @media (max-width: 1075px) {
        flex-direction: column;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 22px;
      padding: 47px 0 57px 54px;

      @media (max-width: 1075px) {
        padding: 0 18px 18px 18px;
      }

      &__header {
        font-size: 48px;
        line-height: 67px;
        font-weight: 600;

        @include mobile {
          font-size: 35px;
          line-height: 45px;
        }
      }

      &__text {
        font-size: 18px;
        line-height: 25px;
      }
    }

    &__img {
      width: 100%;

      &__container {
        flex: 0 0 550px;

        background: url('../images/realtor/realtor-connect.png') center bottom no-repeat;
        background-size: cover;

        @media (max-width: 1075px) {
          background: url('../images/realtor/realtor-connect-mobile.png') center center no-repeat;
          background-size: contain;
          flex: 0 0 340px;
        }
      }
    }
  }

  &__programs {
    background-color: $gray-background;
    padding: 60px 0;

    &__container {
      display: flex;
      gap: 20px;

      @include up-to-tablet2 {
        flex-direction: column;
      }
    }

    &__card {
      background-color: $white;
      border-radius: 12px;

      &__img {
        width: 100%;

        @include tablet1 {
          height: 240px;
        }

        @include desktop {
          height: 240px;
        }

        &__container {

        }
      }

      &__container {
        display: flex;
        flex-direction: column;
        padding: 32px 22px;
      }

      &__header {
        font-size: 28px;
        line-height: 130%;
        font-weight: 600;
        margin-bottom: 4px;
      }

      &__sub {
        color: $blue-main;
        font-size: 22px;
        line-height: 25px;
        font-weight: 600;
        margin-bottom: 12px;
      }

      &__text {
        font-size: 18px;
        line-height: 25px;
      }

      &__button {
        text-transform: none;
        width: 100%;
        margin-top: 32px;
      }
    }
  }

  &__faq {
    padding: 60px 0;
    background-color: $white;

    @include mobile {
      padding-top: 52px;
      padding-bottom: 52px;
    }

    &__header {
      display: block;
      font-size: 48px;
      line-height: 67px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 50px;

      @include mobile {
        text-align: center;
        font-size: 35px;
        line-height: 45px;
      }
    }

    &__accordion {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;

      @include mobile {
        gap: 0;
      }

      &__column {
        flex: 1;

        @include mobile {
          flex-basis: 100%;
        }
      }

      &__item {
        border: 1px solid #DAE1EC;
        border-radius: 12px;
        margin-bottom: 18px;
        background-color: $white;
        flex-basis: 48%;

        &__header-container {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        &__header {
          cursor: pointer;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 30px 16px 22px;
          gap: 22px;

          &__title {
            font-size: 26px;
            line-height: 36px;
            font-weight: 600;

            @include mobile {
              font-size: 22px;
              line-height: 30px;
            }
          }

          &__arrow {
            transition: all 0.2s ease 0s;
          }
        }

        &__header + &__content {
          display: none;
        }

        &__header.is-open + &__content {
          display: block;
          -webkit-animation: accordion-item-fade-in 0.6s;
          animation: accordion-item-fade-in 0.6s;
        }

        &__header.is-open &__header__arrow {
          transform: rotate(180deg);
        }

        &__content {
          font-size: 18px;
          padding-left: 22px;
          padding-right: 30px;
          padding-bottom: 15px;
        }
      }
    }
  }
}
