
.acc-broker {

  &__hero {
    background: url('../images/broker/broker_bg@1x.jpg') center top no-repeat;
    background-size: cover;

    @include mobile {
      background: url('../images/broker/broker_bg_mobile.jpg') center top no-repeat;
      background-size: cover;
    }

    &__header {
      color: white;
      font-size: 48px;
      line-height: 67px;
      font-weight: 600;
      margin-bottom: 16px;
      text-transform: uppercase;

      @include mobile {
        margin-bottom: 12px;
      }
    }

    &__content {
      padding-top: 67px;
      padding-bottom: 67px;

      @include mobile {
        padding: 34px 0 34px 0;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 581px;
      font-size: 22px;
      line-height: 25px;
      color: $white;
      margin-bottom: 40px;

      @include mobile {
        margin-bottom: 22px;
      }

      &__item {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__buttons {
      display: flex;

      @include mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__button {
        display: flex;
        width: 236px;
        height: 55px;
        color: $white;
        background-color: $blue-main;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;

        &:first-child {
          margin-right: 20px;
        }

        &--inverse {
          color: #275EB8;
          background-color: $white;
        }

        @include mobile {
          width: 100%;
          max-width: 500px;
        }

        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }

    .acc-caption {
      margin-bottom: 30px;

      @include mobile {
        font-size: 48px;
        line-height: 58px;
      }
    }
  }

  &__why {
    padding-top: 70px;
    padding-bottom: 70px;

    @include mobile {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &__card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 35px 45px;
      flex: 41%;
      background: $blue-main;
      border-radius: 16px;
      color: $white;

      @include desktop {
        min-height: 150px;
      }

      @include mobile {
        padding: 25px 0;
        flex: 50%;
      }

      &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }

      &__icon {
        width: 28px;
        height: 28px;
        margin-right: 12px;

        @include mobile {
          margin-right: 8px;
        }
      }

      &__title {
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;

        &__container {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-bottom: 8px;
        }
      }

      &__content {
        font-size: 18px;
        line-height: 25px;

        @include mobile {
          padding: 0 25px;
        }
      }
    }
  }

  &__video {
    padding: 60px 0;
    background-color: $blue-main;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        flex-direction: column;
        gap: 22px;
      }
    }

    &__poster {
      margin-right: 48px;
      max-width: 640px;
      cursor: pointer;

      @include mobile {
        max-width: none;
        width: 100%;
        margin-right: 0;
      }

      img {
        width: 100%;
        border-radius: 16px;
      }
    }

    &__caption {
      display: flex;
      flex-direction: column;
      color: $white;
      text-align: left;

      &__title {
        font-size: 48px;
        line-height: 62px;
        font-weight: 600;
        margin-bottom: 12px;

        @include mobile {
          text-align: center;
        }
      }

      &__desc {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 40px;

        @include mobile {
          text-align: center;
        }
      }

      // todo: extract to a separate component
      &__button {
        display: flex;
        width: 236px;
        height: 55px;
        color: $white;
        background-color: $blue-main;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;

        @include mobile {
          width: 100%;
        }

        &--inverse {
          color: #275EB8;
          background-color: $white;
        }
      }
    }
  }

  &__steps {
    background-color: $gray-background;

    &__container {
      min-height: 800px;
      display: flex;
      flex-wrap: wrap;
      padding-top: 64px;
      padding-bottom: 64px;
      gap: 50px;

      @include mobile {
        min-height: 0;
      }
    }

    &__img {
      height: 100%;

      @include up-to-tablet2 {
        display: none;
      }
    }

    &__accordion-container {
      padding-top: 65px;
      flex: 1;

      @include up-to-tablet2 {
        padding-top: 0;
      }
    }

    &__header {
      font-size: 48px;
      line-height: 68px;
      font-weight: 600;
      margin-bottom: 32px;
      display: block;

      @include mobile {
        padding-left: 0;
        text-align: center;
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 50px;
      }
    }

    &__accordion {
      padding-left: 25px;

      &__item {
        border: 1px solid #DAE1EC;
        border-radius: 12px;
        margin-bottom: 18px;
        background-color: $white;

        &__header-container {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        &__header {
          cursor: pointer;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 30px;
          height: 80px;

          &__num {
            width: 50px;
            height: 50px;
            background-color: #3A7AE0;
            border-radius: 8px;
            color: $white;
            font-size: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -25px;
            flex-shrink: 0;
          }

          &__title {
            font-size: 25px;
            font-weight: 600;
          }

          &__arrow {
            transition: all 0.2s ease 0s;
          }
        }

        &__header + &__content {
          display: none;
        }

        &__header.is-open + &__content {
          display: block;
          -webkit-animation: accordion-item-fade-in 0.6s;
          animation: accordion-item-fade-in 0.6s;
        }

        &__header.is-open &__header__arrow {
          transform: rotate(180deg);
        }

        &__content {
          font-size: 18px;
          padding-left: 45px;
          padding-right: 30px;
          padding-bottom: 15px;
        }
      }
    }
  }

  &__faq {
    padding: 60px 0;
    background-color: $white;

    @include mobile {
      background-color: $gray-background;
      padding-top: 0;
      padding-bottom: 0;
    }

    &__header {
      display: block;
      font-size: 48px;
      line-height: 67px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 50px;

      @include mobile {
        text-align: center;
        font-size: 35px;
        line-height: 45px;
      }
    }

    &__accordion {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;

      &__column {
        flex: 1;

        @include mobile {
          flex-basis: 100%;
        }
      }

      &__item {
        border: 1px solid #DAE1EC;
        border-radius: 12px;
        margin-bottom: 18px;
        background-color: $white;
        flex-basis: 48%;

        &__header-container {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        &__header {
          cursor: pointer;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 30px 16px 22px;
          gap: 22px;

          &__title {
            font-size: 26px;
            line-height: 36px;
            font-weight: 600;

            @include mobile {
              font-size: 22px;
              line-height: 30px;
            }
          }

          &__arrow {
            transition: all 0.2s ease 0s;
          }
        }

        &__header + &__content {
          display: none;
        }

        &__header.is-open + &__content {
          display: block;
          -webkit-animation: accordion-item-fade-in 0.6s;
          animation: accordion-item-fade-in 0.6s;
        }

        &__header.is-open &__header__arrow {
          transform: rotate(180deg);
        }

        &__content {
          font-size: 18px;
          padding-left: 22px;
          padding-right: 30px;
          padding-bottom: 15px;
        }
      }
    }
  }

  &__info {
    background-color: $gray-background;

    @include mobile {
      .acc-container {
        padding: 0;
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      padding-top: 64px;
      //padding-bottom: 64px;

      @include mobile {
        flex-direction: column;
        padding-bottom: 0;
        min-height: 0;
      }
    }

    &__img {
      height: 100%;
      width: 100%;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;

      @include mobile {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &__container {
        flex: 1;

        @include mobile {
          padding-left: 33px;
          padding-right: 33px;
          background-color: $blue-main;
          padding-top: 60px;
        }
      }
    }

    &__desc {
      flex: 1;
      background-color: $blue-main;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 37px 35px 37px 52px;
      gap: 32px;

      @include mobile {
        padding-left: 33px;
        padding-right: 33px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        gap: 40px;
        padding-bottom: 60px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        gap: 12px;
        color: $white;
        font-size: 18px;
        line-height: 25px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      &__button {
        margin-top: 16px;
        display: flex;
        width: 236px;
        height: 55px;
        color: $white;
        background-color: $blue-main;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;

        @include mobile {
          width: 100%;
        }

        &--inverse {
          color: #275EB8;
          background-color: $white;
        }
      }
    }
  }
}
