// spacing helper
$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100);
$sides: (top, bottom, left, right);
@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
// all sides
@each $space in $spaceamounts {
  .m-a-#{$space} {
    margin: #{$space}px !important;
  }
  .p-a-#{$space} {
    padding: #{$space}px !important;
  }
}

// responsive mixins
@mixin desktop {
  @media (min-width: 1240px) {
    @content;
  }
}

@mixin tablet1 {
  @media (min-width: 992px) and (max-width: 1240px) {
    @content;
  }
}

@mixin tablet2 {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin tablet {
  // left for old styles
  @media (min-width: 768px) and (max-width: 1240px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin up-to-tablet2 {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin up-to-desktop {
  @media (max-width: 1239px) {
    @content;
  }
}

// retina mixin
@mixin retina {
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    @content;
  }
}
