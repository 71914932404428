
.acc-faq {
  .acc-container {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .acc-section__header__text {
    font-weight: 900;
  }  
}

.faq {
  &__lines {
    margin: 20px 0;
  }

  &__line {
    padding: 10px 0;

    @include desktop {
      width: 80%;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #eeeeee;
    }

    &__title,
    &__value {
      display: inline-block;
      vertical-align: top;
    }

    &__title {
      width: 40%;
      margin-right: 16px;
      font-weight: bold;

      @include mobile {
        width: 100%;
      }
    }

    &__value {
      width: 55%;

      @include mobile {
        width: 100%;
      }
    }
  }
}