
.acc-blog {
  padding: 16px 0 48px 0;

  &__item {
    background-color: $lightgray;
    padding: 16px;

    &__header {
      font-size: 36px;
      line-height: 1.1;
      padding: 0;
      margin: 0;
    }
  }
}