
input[type="text"],
input[type="email"],
textarea {
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px hsla(0,0%,4%,.1);
  border-radius: 3px;
  border: 1px solid transparent;
  color: #363636;
  width: 100%;
  line-height: 1.5;
  font-size: 1rem;
  padding: calc(.375em - 1px) calc(.625em - 1px);
  display: inline-flex;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  vertical-align: top;
  background-color: #fff;
  border-color: #dbdbdb;

  &:hover {
    border-color: #b5b5b5;
  }

  &:focus {
    border-color: #69f;
    box-shadow: 0 0 0 0.125em rgba(102, 153, 255, 0.25);
  }

  &.is-danger {
    border-color: $red;

    &:hover {
      border-color: $red;
    }

    &:focus {
     box-shadow: 0 0 0 .125em rgba(255,56,96,.25);
    }
  }
}

input[type="text"],
input[type="email"] {
  height: 36px;
}

textarea {
  min-height: 120px;
}

.form-error {
  display: none;
  color: $red;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}
