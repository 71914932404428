/*new */
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Bold.woff2') format('woff2'),
      url('../fonts/SourceSansPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Black.woff2') format('woff2'),
      url('../fonts/SourceSansPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-BlackItalic.woff2') format('woff2'),
      url('../fonts/SourceSansPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-BoldItalic.woff2') format('woff2'),
      url('../fonts/SourceSansPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
      url('../fonts/SourceSansPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Italic.woff2') format('woff2'),
      url('../fonts/SourceSansPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
      url('../fonts/SourceSansPro-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-LightItalic.woff2') format('woff2'),
      url('../fonts/SourceSansPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Light.woff2') format('woff2'),
      url('../fonts/SourceSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Regular.woff2') format('woff2'),
      url('../fonts/SourceSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/SourceSansPro-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
      url('../fonts/SourceSansPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


/* old */
/* alegreya-sans-300 - latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/alegreya-sans-v8-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Alegreya Sans Light'), local('AlegreyaSans-Light'),
       url('../fonts/alegreya-sans-v8-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/alegreya-sans-v8-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/alegreya-sans-v8-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/alegreya-sans-v8-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/alegreya-sans-v8-latin-300.svg#AlegreyaSans') format('svg'); /* Legacy iOS */
}

/* alegreya-sans-regular - latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/alegreya-sans-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Alegreya Sans Regular'), local('AlegreyaSans-Regular'),
       url('../fonts/alegreya-sans-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/alegreya-sans-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/alegreya-sans-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/alegreya-sans-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/alegreya-sans-v8-latin-regular.svg#AlegreyaSans') format('svg'); /* Legacy iOS */
}

/* alegreya-sans-700 - latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/alegreya-sans-v8-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Alegreya Sans Bold'), local('AlegreyaSans-Bold'),
       url('../fonts/alegreya-sans-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/alegreya-sans-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/alegreya-sans-v8-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/alegreya-sans-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/alegreya-sans-v8-latin-700.svg#AlegreyaSans') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../fonts/roboto-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v18-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v18-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
