
.acc-programs {

  &__hero {

    &--fix-flip {
      background: url('../images/programs/hero-bg-fix-flip.jpg') center top no-repeat #4f8af1;
      background-size: cover;

      @include mobile {
        background: url('../images/programs/hero-bg-fix-flip-mobile.jpg') center top no-repeat #4f8af1;
        background-size: cover;
      }
    }

    &--bridge {
      background: url('../images/programs/hero-bg-bridge.jpg') center right no-repeat #4f8af1;
      background-size: cover;

      @include mobile {
        background: url('../images/programs/hero-bg-bridge-mobile.jpg') center top no-repeat #4f8af1;
        background-size: cover;
      }
    }

    &--ground-up {
      background: url('../images/programs/hero-bg-ground-up.jpg') center top no-repeat #4f8af1;
      background-size: cover;

      @include mobile {
        background: url('../images/programs/hero-bg-ground-up-mobile.jpg') center top no-repeat #4f8af1;
        background-size: cover;
      }
    }

    &--rental {
      background: url('../images/programs/hero-bg-rental.jpg') center top no-repeat #4f8af1;
      background-size: cover;

      @include mobile {
        background: url('../images/programs/hero-bg-rental-mobile.jpg') center top no-repeat #4f8af1;
        background-size: cover;
      }
    }

    &--multifamily {
      background: url('../images/programs/hero-bg-multifamily.jpg') center top no-repeat #4f8af1;
      background-size: cover;

      @include mobile {
        background: url('../images/programs/hero-bg-multifamily-mobile.jpg') center top no-repeat #4f8af1;
        background-size: cover;
      }
    }

    &--fix-flip,
    &.acc-programs__hero--rental,
    &.acc-programs__hero--ground-up,
    &.acc-programs__hero--bridge,
    &.acc-programs__hero--multifamily {
      .acc-programs__hero__content {
        max-width: 100%;
        padding: 127px 0;
        display: flex;
        flex-direction: column;
        align-items: start;

        @include mobile {
          padding: 67px 0 64px 0;
        }

        .acc-caption {
          font-size: 80px;
          line-height: 88px;
          font-weight: 600;
          margin-bottom: 8px;
          margin-top: 0;
          text-transform: none;

          @include mobile {
            font-size: 45px;
            line-height: 54px;
            text-transform: uppercase;
            //max-width: 280px;
          }
        }
      }
    }

    &.acc-programs__hero--fix-flip {
      .acc-programs__hero__content {
        padding: 83px 0;

        @include mobile {
          padding: 67px 0 77px 0;
        }
      }

      .acc-programs__hero__content .acc-caption {
        @include mobile {
          width: 300px;
        }
      }
    }

    &.acc-programs__hero--rental {
      .acc-programs__hero__content {
        padding: 127px 0;

        @include mobile {
          padding: 99px 0;
        }

        .acc-caption {
          font-size: 100px;
          line-height: 88px;

          @include mobile {
            font-size: 45px;
            line-height: 54px;
          }
        }
      }
    }

    &.acc-programs__hero--bridge {
      .acc-programs__hero__content {
        padding: 127px 0;

        @include mobile {
          padding: 99px 0;
        }
      }

      .acc-programs__hero__content .acc-caption {
        @include tablet2 {
          width: 349px;
        }
      }
    }

    &.acc-programs__hero--ground-up {
      .acc-programs__hero__content {
        @include mobile {
          padding: 72px 0;
        }
      }
    }

    &.acc-programs__hero--multifamily {
      .acc-programs__hero__content {
        .acc-caption {
          @include mobile {
            font-size: 42px;
          }
        }
      }
    }

    &__content {
      padding: 80px 0;

      .acc-caption {
        @include tablet2 {
          font-size: 48px;
          line-height: 58px;
        }

        @include mobile {
          font-size: 36px;
          line-height: 43px;
        }
      }
    }
  }

  &__info {
    padding: 60px 0 40px 0;
    background-color: $gray-background;

    @include mobile {
      padding: 45px 0 50px 0;
    }

    &__content {
      display: flex;
      justify-content: center;
      text-align: center;

      span {
        max-width: 580px;
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  // todo: rename to just info and remove old info
  &__info-new {
    @include mobile {
      .acc-container {
        padding: 0;
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      padding-top: 64px;
      padding-bottom: 64px;

      @include mobile {
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;
        min-height: 0;
      }
    }

    &__img {
      height: 100%;
      width: 100%;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;

      @include mobile {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &__container {
        flex: 1;

        @include mobile {
          padding-left: 33px;
          padding-right: 33px;
          background-color: $blue-main;
          padding-top: 60px;
        }
      }
    }

    &__desc {
      flex: 1;
      background-color: $blue-main;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 37px 50px 37px 52px;
      gap: 32px;

      @include mobile {
        padding-left: 33px;
        padding-right: 33px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        gap: 18px;
        padding-bottom: 60px;
        padding-top: 22px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        gap: 12px;
        color: $white;
        font-size: 22px;
        line-height: 30px;

        @include mobile {
          font-size: 18px;
          line-height: 25px;
        }

        img {
          width: 50px;
          height: 50px;
        }
      }

      &__button {
        margin-top: 16px;
        display: flex;
        width: 236px;
        height: 55px;
        color: $white;
        background-color: $blue-main;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;

        @include mobile {
          width: 100%;
          margin-top: 22px;
        }

        &--inverse {
          color: #275EB8;
          background-color: $white;
        }
      }
    }
  }

  &__types {
    background-color: $gray-background;
    padding-top: 64px;
    padding-bottom: 64px;

    &--multifamily {
      padding-top: 64px;
    }

    &--fix-flip,
    &.acc-programs__types--rental,
    &.acc-programs__types--ground-up,
    &.acc-programs__types--bridge,
    &.acc-programs__types--multifamily {
      .acc-programs__types__headers__item {
        &:nth-child(3) {
          margin-right: 85px;

          @include tablet1 {
            margin-right: 82px;
          }

          @include tablet2 {
            margin-right: 42px;
          }

          @include mobile {
            margin-right: 34px;
          }
        }
      }

      .acc-programs__types__table__row__item {
        &:nth-child(2) {
          @include tablet1 {
            min-width: 230px;
          }
        }

        &:nth-child(3) {
          margin-right: 108px;

          @include tablet1 {
            margin-right: 82px;
          }

          @include tablet2 {
            margin-right: 42px;
          }

          @include mobile {
            margin-right: 34px;
          }
        }
      }
    }

    &.acc-programs__types--fix-flip, &.acc-programs__types--rental {
      .acc-programs__types__table__row__item--merged {
        width: calc(100% - 370px) !important;
        margin-left: 150px;

        @include tablet2 {
          width: calc(100% - 220px) !important;
          margin-left: 0;
        }

        @include mobile {
          width: calc(100% - 170px) !important;
          margin-left: 0;
        }
      }
    }

    &.acc-programs__types--ground-up,
    &.acc-programs__types--bridge,
    &.acc-programs__types--multifamily {
      .acc-programs__types__table__row__item {
        &:nth-child(2) {
          width: 245px !important;
          margin-right: 365px;

          @include tablet1 {
            margin-right: 225px;
          }

          @include tablet2 {
            margin-right: 156px;
          }

          @include mobile {
            margin-right: 15px;
          }
        }
      }

      .acc-programs__types__headers__item {
        &:nth-child(3) {
          margin-right: 425px;

          @include tablet1 {
            margin-right: 263px;
          }

          @include tablet2 {
            margin-right: 173px;
          }

          @include mobile {
            margin-right: 120px;
          }
        }
      }
    }

    &.acc-programs__types--ground-up {
      .acc-programs__types__headers__item {
        &:nth-child(3) {
          margin-right: 375px;

          @include mobile {
            margin-right: 35px;
          }

          //@include tablet1 {
          //  margin-right: 263px;
          //}
          //
          //@include tablet2 {
          //  margin-right: 173px;
          //}
          //
          //@include mobile {
          //  margin-right: 120px;
          //}
        }
      }
    }

    &.acc-programs__types--fix-flip {
      .acc-programs__types__headers__item {
        &:first-child {
          width: 305px;

          @include mobile {
            width: 170px;
          }
        }
      }
    }

    &.acc-programs__types--rental {
      .acc-programs__types__headers__item {
        &:first-child {
          //width: 183px;

          @include mobile {
            width: 104px;
          }

          @include tablet1 {

          }
        }

        &:nth-child(3) {
          margin-right: 144px;

          @include mobile {
            margin-right: 57px;
          }

          @include tablet1 {
            margin-right: 115px;
          }

          @include tablet2 {
            margin-right: 78px;
          }

        }
      }
    }

    &.acc-programs__types--bridge, &.acc-programs__types--multifamily {
      .acc-programs__types__headers__item {
        &:nth-child(3) {
          //margin-right: 408px;

          //@include tablet1 {
          //  margin-right: 263px;
          //}
          //
          //@include tablet2 {
          //  margin-right: 173px;
          //}

          @include mobile {
            margin-right: 85px;
          }
        }
      }

      //.acc-programs__types__table {
      //  margin-bottom: 21px;
      //}
    }

    &.acc-programs__types--multifamily {
      .acc-programs__types__table {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    }

    .acc-container {
      @include tablet1 {
        padding-left: 16px;
        padding-right: 16px;
      }

      @include tablet2 {
        padding-left: 24px;
        padding-right: 24px;
      }

      @include mobile {
        //padding-left: 20px;
        //padding-right: 20px;
        padding: 0;
      }
    }

    &__headers {
      display: flex;
      justify-content: space-between;
      padding: 22px 0;
      background-color: $white;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom: 1px solid #eeeeee;

      @include mobile {
        padding: 6px 0;
      }

      &--partly {
        max-width: 484px;

        .acc-programs__types__headers__item {
          padding-right: 24px;
        }
      }

      &__item {
        font-size: 22px;
        line-height: 30px;
        font-weight: 600;
        text-align: center;
        //width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 22px;

        @include mobile {
          font-size: 16px;
          line-height: 25px;
        }

        //span {
        //  max-width: 131px;
        //}

        .button-new {
          height: 41px;
          width: 169px;
        }

        &:first-child {
          width: 245px;

          @include mobile {
            width: 170px;
          }          
        }

        &:nth-child(3) {
          @include tablet1 {
            margin-right: 80px;
          }

          @include tablet2 {
            margin-right: 40px;
          }

          @include mobile {
            margin-right: 15px;
          }          
        }        
      }
    }

    &__table {
      background-color: white;
      margin-bottom: 40px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      &--partly {
        max-width: 484px;

        .acc-programs__types__table__row {
          padding-right: 24px;
        }
      }

      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child):not(.acc-programs__types__table__row--wo-border):not(.acc-programs__types__table__row--subheader) {
          border-bottom: 1px solid #eeeeee;
        }

        &--subheader {
          .acc-programs__types__table__row__item.acc-programs__types__table__row__item {
            width: 100%;
            color: #4f8af1;

            @include tablet2 {
              width: 210px;
            }

            @include mobile {
              width: 143px;
            }
          }
        }

        &--note {
          .acc-programs__types__table__row__item.acc-programs__types__table__row__item {
            font-size: 16px;
            width: 100%;

            @include tablet2 {
              width: 210px;
            }

            @include mobile {
              width: 143px;
            }
          }
        }

        &__item {
          width: 160px;
          text-align: center;
          color: #2e3139;
          font-size: 18px;
          line-height: 25px;
          padding: 12px 0;

          @include tablet2 {
            font-size: 14px;
          }

          @include mobile {
            font-size: 16px;
            line-height: 18px;
          }

          &:first-child {
            width: 280px;
            text-align: left;
            font-size: 22px;
            line-height: 30px;
            font-weight: 600;
            padding-left: 22px;
            box-sizing: border-box;

            display: flex;
            gap: 8px;
            align-items: center;

            @include tablet1 {
              padding-left: 15px;
            }

            @include tablet2 {
              font-size: 16px;
              padding-left: 16px;
            }

            @include mobile {
              width: 170px;
              font-size: 16px;
              line-height: 18px;
              padding-left: 25px;
            }
          }

          &:nth-child(3) {
            @include tablet1 {
              margin-right: 80px;
            }

            @include tablet2 {
              margin-right: 40px;
            }

            @include mobile {
              margin-right: 15px;
            }            
          }

          &--with-warning {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
          }
        }
      }

      &__underline {
        font-size: 20px;
        margin-bottom: 40px;

        @include mobile {
          text-align: center;
          padding-left: 33px;
          padding-right: 33px;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: center;

      @include mobile {
        padding-left: 33px;
        padding-right: 33px;
      }

      .button-new {
        width: 284px;

        @include mobile {
          width: 100%;
        }
      }

      &--partly {
        max-width: 484px;

        .acc-programs__types__buttons__item {
          padding-right: 24px;
        }
      }

      &__item {
        text-align: center;
        width: 160px;


        &:first-child {
          width: 220px;

          @include mobile {
            width: 170px;
          }
        }

        @include mobile {
          width: 150px;
        }

        .button {
          width: 100%;

          @include mobile {
            width: 98%;
          }
        }

        &:nth-child(3) {
          @include tablet1 {
            margin-right: 80px;
          }

          @include tablet2 {
            margin-right: 40px;
          }

          @include mobile {
            margin-right: 15px;
          }          
        }
      }
    }
  }

  &__widgets {
    background-color: $gray-background;
    padding-bottom: 64px;

    @include mobile {
      padding-bottom: 0;
    }

    .acc-container {
      background-color: $white;
      border-radius: 16px;
      padding-top: 34px;
      padding-bottom: 34px;

      @include mobile {
        padding-top: 40px;
        padding-bottom: 45px;
      }
    }

    &__lines {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 800px;
      margin: 0 auto;

      @include mobile {
        gap: 32px;
      }
    }

    &__line {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include mobile {
        flex-direction: column;
        justify-content: center;
        gap: 16px;
      }

      &__header {
        box-sizing: border-box;
        width: 50%;
        font-size: 22px;
        line-height: 30px;
        font-weight: 600;

        @include mobile {
          width: 100%;
          text-align: center;
          font-size: 25px;
        }
      }

      &__button {
        @include mobile {
          width: 100%;
        }

        .button-new {
          width: 380px;

          @include mobile {
            width: 100%;
          }
        }
      }
    }
  }

  &__why {
    padding: 60px 0;

    &__header {
      font-size: 48px;
      line-height: 67px;
      font-weight: 600;
      margin-bottom: 50px;
    }

    &__card {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 25px 44px;
      flex: 41%;
      background: $blue-main;
      border-radius: 16px;
      color: $white;

      @include mobile {
        padding: 0;
        flex: 100%;
      }

      &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }

      &__icon {
        margin-right: 12px;

        @include mobile {
          margin-right: 8px;
        }
      }

      &__title {
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;

        &__container {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-bottom: 8px;

          @include mobile {
            margin-top: 22px;
          }
        }
      }

      &__content {
        font-size: 18px;
        line-height: 25px;

        @include mobile {
          padding: 0 25px 22px 25px;
        }
      }
    }
  }

  &__steps {
    background-color: $gray-background;

    &__container {
      min-height: 800px;
      display: flex;
      flex-wrap: wrap;
      padding-top: 64px;
      padding-bottom: 64px;

      @include mobile {
        min-height: 0;
      }
    }

    &__img {
      height: 100%;

      @include up-to-tablet2 {
        display: none;
      }
    }

    &__accordion-container {
      padding-top: 65px;
      flex: 1;

      @include up-to-tablet2 {
        padding-top: 0;
      }
    }

    &__header {
      font-size: 48px;
      line-height: 68px;
      font-weight: 600;
      padding-left: 55px;
      margin-bottom: 32px;
      display: block;

      @include mobile {
        padding-left: 0;
        text-align: center;
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 50px;
      }
    }

    &__accordion {
      padding-left: 80px;

      @include up-to-tablet2 {
        padding-left: 25px;
      }

      &__item {
        border: 1px solid #DAE1EC;
        border-radius: 12px;
        margin-bottom: 18px;
        background-color: $white;

        &__header-container {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        &__header {
          cursor: pointer;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 30px;
          height: 80px;

          &__num {
            width: 50px;
            height: 50px;
            background-color: #3A7AE0;
            border-radius: 8px;
            color: $white;
            font-size: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -25px;
            flex-shrink: 0;
          }

          &__title {
            font-size: 25px;
            font-weight: 600;

            @include mobile {
              font-size: 22px;
            }
          }

          &__arrow {
            transition: all 0.2s ease 0s;
          }
        }

        &__header + &__content {
          display: none;
        }

        &__header.is-open + &__content {
          display: block;
          -webkit-animation: accordion-item-fade-in 0.6s;
          animation: accordion-item-fade-in 0.6s;
        }

        &__header.is-open &__header__arrow {
          transform: rotate(180deg);
        }

        &__content {
          font-size: 18px;
          padding-left: 45px;
          padding-right: 30px;
          padding-bottom: 15px;
        }
      }
    }
  }

  &__faq {
    //padding: 60px 0;
    padding-bottom: 60px;
    background-color: $white;

    @include mobile {
      padding-bottom: 0;
      padding-top: 60px;
      background-color: $gray-background;
    }

    &__header {
      display: block;
      font-size: 48px;
      line-height: 67px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 50px;

      @include mobile {
        text-align: center;
        font-size: 35px;
        line-height: 45px;
      }
    }

    &__accordion {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;

      @include mobile {
        gap: 0;
      }

      &__column {
        flex: 1;

        @include mobile {
          flex-basis: 100%;
        }
      }

      &__item {
        border: 1px solid #DAE1EC;
        border-radius: 12px;
        margin-bottom: 18px;
        background-color: $white;
        flex-basis: 48%;

        &__header-container {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        &__header {
          cursor: pointer;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 30px 16px 22px;
          gap: 22px;

          &__title {
            font-size: 26px;
            line-height: 36px;
            font-weight: 600;

            @include mobile {
              font-size: 22px;
              line-height: 30px;
            }
          }

          &__arrow {
            transition: all 0.2s ease 0s;
          }
        }

        &__header + &__content {
          display: none;
        }

        &__header.is-open + &__content {
          display: block;
          -webkit-animation: accordion-item-fade-in 0.6s;
          animation: accordion-item-fade-in 0.6s;
        }

        &__header.is-open &__header__arrow {
          transform: rotate(180deg);
        }

        &__content {
          font-size: 18px;
          padding-left: 22px;
          padding-right: 30px;
          padding-bottom: 15px;
        }
      }
    }
  }
}
