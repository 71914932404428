
.acc-about-us {
  background-color: $gray-background;

  &__hero {
    background: url('../images/realtor/realtor-hero-bg.jpg') center bottom no-repeat;
    background-size: cover;

    &__content {
      padding: 67px 0 90px 0;

      @include mobile {
        padding: 34px 0;
      }
    }

    .acc-caption {
      font-size: 80px;
      line-height: 88px;
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 0;

      @include mobile {
        font-size: 45px;
        line-height: 54px;
      }
    }
  }
  
  &__description {
    background-color: $gray-background;
    padding: 64px 0 44px 0;

    &__item {
      display: flex;
      gap: 50px;

      @include up-to-tablet2 {
        flex-direction: column;
        gap: 22px;
      }

      &__img {
        border-radius: 12px;
        max-width: 550px;

        @include mobile {
          width: 100%;
        }

        @include tablet1 {
          width: 100%;
        }

        &__container {
          display: flex;
          justify-content: center;
          flex: 0 0 550px;

          @include up-to-tablet2 {
            flex: unset;
          }
        }
      }

      &__container {
        display: flex;
        flex-direction: column;

        @include up-to-tablet2 {
          padding-top: 0;
        }
      }

      &__title {
        font-size: 48px;
        line-height: 67px;
        font-weight: 600;

        @include mobile {
          font-size: 35px;
          line-height: 45px;
        }
      }

      &__text {
        font-size: 18px;
        line-height: 25px;
        
        p {
          margin: 12px 0;
        }
      }
    }
  }
  
  .acc-container {
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    gap: 64px;

    @include mobile {
      padding-top: 52px;
      padding-bottom: 52px;
      gap: 52px;
    }
  }

  .acc-section__header {
    margin-bottom: 32px;

    @include mobile {
      justify-content: center;
      margin-bottom: 22px;
    }
  }

  .acc-section__header__text {
    font-weight: 600;
    font-size: 48px;
    line-height: 67px;
    color: #18181B;
    margin: 0;

    @include mobile {
      font-size: 35px;
      line-height: 45px;
      text-align: center;
    }
  }

  &__section {

  }

  &__photos {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @include mobile {
      flex-direction: column;
      align-items: center;
      gap: 22px;
    }
  }

  &__person {
    @include mobile {
      background-color: $white;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &__photo {
      max-height: 400px;
      height: 100%;
      margin-bottom: 10px;

      @include mobile {
        max-height: unset;
        height: unset;
        max-width: 340px;
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
    }

    &__text {
      @include mobile {
        padding: 0 12px 14px 12px;
      }
    }

    &__name {
      font-size: 26px;
      line-height: 42px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    &__role {
      font-size: 20px;
      line-height: 25px;
      color: #18181B;
      opacity: 50%;

      @include mobile {
        opacity: 100%;
      }
    }
  }

  &__team__header {
    font-size: 26px;
    margin-left: 18px;

    @include mobile {
      text-align: center;
    }
  }
}
