.acc-footer {
  background-color: #4F8AF1;

  .acc-container {
    box-sizing: border-box;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet1 {
      justify-content: center;
    }

    @include mobile {
      height: auto;
      min-height: 128px;
      padding-top: 8px;
      padding-bottom: 8px;
      flex-wrap: wrap;
    }
  }

  &__logo-container {
    @include mobile {
      width: 100%;
      text-align: center;
    }
  }

  &__logo {
    &__image {
      @include mobile {
        width: 87px;
        height: auto;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    @include tablet1 {
      margin-left: 70px;
      margin-right: 49px;
    }

    @include tablet2 {
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 24px;
      margin-right: 36px;
    }

    @include mobile {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      position: relative;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      padding: 16px 0;

      &:not(:last-child) {
        margin-right: 36px;
      }

      .acc-dropdown__activator,
      a {
        color: $white;
        text-decoration: none;
      }

      .acc-dropdown__content__item a {
        color: #4B4B4B;

        &:hover {
          color: #4F8AF1;
        }
      }

      @include tablet1 {
        font-size: 14px;
        line-height: 14px;
      }

      @include tablet2 {
        font-size: 14px;
        line-height: 16px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 16px;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  &__contacts {
    a {
      color: $white;
      text-decoration: none;
    }

    @include desktop {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.04em;
    }

    @include mobile {
      margin-top: 15px;
    }

    &__item {
      display: flex;
      align-items: center;
      color: $white;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      @include mobile {
        justify-content: center;
        margin-bottom: 4px;
      }
    }

    &__icon {
      max-height: 28px;
      max-width: 28px;
      margin-right: 15px;

      @include tablet1 {
        width: 26px;
        height: 26px;
        margin-right: 9px;
      }

      @include tablet2 {
        width: 26px;
        height: 26px;
        margin-right: 9px;
      }

      @include mobile {
        width: 22px;
        height: 22px;
        margin-right: 5.5px;
      }      
    }
  }

  &__social {
    width: 100%;
    margin-top: 16px;

    &--desktop {
      display: block;

      @media (max-width: 955px) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media (max-width: 955px) {
        display: block;
        margin: -16px 16px 16px 16px;
        text-align: center;
      }

      @include mobile {
        margin-top: 0px;
        margin-left: 0;
      }
    }

    img {
      width: auto;
      height: 32px;
      margin: 0 4px;
      border: 0;

      @media (max-width: 1044px) {
        margin: 0;
      }
    }
  }

  &__dmca {
    margin-top: 16px;

    &--desktop {
      display: block;

      @media (max-width: 955px) {
        display: none;
      }
    }
  }

  &__copyrights {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 116px;
    background-color: #EEF4FF;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: #4B4B4B;

    &__link {
      color: #4B4B4B;
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.acc-footer-new {
  padding-top: 58px;
  padding-bottom: 75px;
  background-color: #2E3139;

  & > .acc-container {
    display: flex;
    flex-direction: column;
  }

  &__nav-container {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;
    padding-bottom: 86px;

    @include mobile {
      flex-direction: column;
      padding-bottom: 0;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    flex: 1;
    gap: 15px;

    @include mobile {
      flex-wrap: wrap;
      gap: 80px;
      justify-content: unset;
    }

    &__section {
      display: flex;
      flex-direction: column;

      @include mobile {
        flex: 1
      }

      &__container {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }

    &__header {
      color: $white;
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    a {
      text-decoration: none;
      color: $white;
      font-size: 18px;
      line-height: 25px;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  &__newsletter {
    flex: 1;

    &__header {
      color: $white;
      text-transform: uppercase;
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 20px;
      display: block;
    }

    &__form {
      display: flex;
      gap: 20px;

      @include mobile {
        gap: 5px;
      }

      &__field {
        position: relative;

        @include mobile {
          width: 100%;
        }

        &__container {
          display: flex;
          gap: 10px;

          @include mobile {
            gap: 5px;
          }
        }

        .form-error {
          position: relative;
        }
      }
    }

    &__input {
      border-color: #DAE1EC !important;
      border-radius: 8px !important;
      height: 56px !important;
      color: $white !important;
      background-color: #2E3139 !important;
      width: 380px !important;

      @include mobile {
        width: 100% !important;
      }

      &.is-danger {
        border-color: $red !important;
      }
    }

    .button {
      text-transform: none;
      width: 180px;
      height: 56px;
      border-radius: 5px;

      @include mobile {
        width: 93px;
        flex-shrink: 0;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #DAE1EC;
    padding-top: 37px;
    flex-wrap: wrap;
    gap: 20px;

    @include mobile {
      flex-direction: column;
      border-top: 0;
      padding-top: 0;
      margin-bottom: 68px;
    }

    &__logo {
      width: 100px;
      height: 35px;

      &__image {
        @include mobile {
          width: 100%;
          height: 100%;
        }
      }

      @include mobile {
        margin-bottom: 35px;
        width: 145px;
        height: 51px;
      }
    }

    &__contacts {
      display: flex;
      gap: 57px;
      color: $white;
      font-size: 18px;
      line-height: 25px;
      flex-wrap: wrap;

      @include mobile {
        flex-direction: column;
        align-items: center;
        gap: 22px;
        text-align: center;
      }

      @include tablet {
        gap: 20px;
      }

      a {
        color: $white;
        text-decoration: none;
      }

      &__phoneemail-container {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }
  }
}