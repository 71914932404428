
// colors
$blue: #6699ff;
$blue-invert: #ffffff;
$blue-hover: #365dc2;
$orange: #f2b229;
$orange-invert: #ffffff;
$orange-hover: #e47d1f;
$red: #bd4343;
$gray: #bbbbbb;
$lightgray: #f0f0f0;
$white: #ffffff;

$text: #4b4b4b;
$blue-main: #4f8af1;
$gray-background: #f2f4fa;

// fonts
$font-main: 'Alegreya Sans', Helvetica, Arial, sans-serif;
$font-alt: 'Roboto', Helvetica, Arial, sans-serif;
