:root {
  --swiper-navigation-size: 14px;
  --swiper-scrollbar-drag-bg-color: $blue-main;
  --swiper-scrollbar-bg-color: #FFFFFF;
  --swiper-scrollbar-sides-offset: 200px;
}

.acc-title {
  padding-top: 0;

  &__hero {
    box-sizing: border-box;
    height: 855px;
    padding-top: 150px;
    //background: url('../images/home_bg@1x.jpg') center top no-repeat;
    background: url('../images/home_bg_new@1x.jpg') center top no-repeat;
    background-size: cover;

    @include retina {
      //background: url('../images/home_bg@2x.jpg') center top no-repeat;
      background: url('../images/home_bg_new@2x.jpg') center top no-repeat;
      background-size: cover;
    }

    @include tablet {
      padding-top: 54px;
      height: 745px;
    }

    @include mobile {
      background: url('../images/home_bg_mobile.jpg') center top no-repeat;
      background-size: cover;
      padding-top: 24px;
      height: 550px;
    }

    &__header {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #2E3139;

      @include mobile {
        font-size: 24px;
        line-height: 29px;
      }
    }

    .acc-home-caption {
      font-size: 80px;
      line-height: 88px;
      text-transform: uppercase;
      color: $white;
      margin-top: 15px;
      font-weight: normal;

      @include mobile {
        margin-bottom: 14px;
      }

      span {
        font-weight: 900;
      }

      @include mobile {
        font-size: 45px;
        line-height: 54px;
      }

      .acc-home-caption__highlight {
        color: $orange;
        font-weight: normal;
      }
    }

    &__buttons {
      display: flex;
      margin-top: 37px;
      margin-bottom: 70px;

      @include mobile {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        gap: 19px;
      }

      &__button {
        display: flex;
        width: 236px;
        height: 55px;
        color: $white;
        background-color: #F2B229;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;

        &:first-child {
          margin-right: 20px;
        }

        &--inverse {
          color: #275EB8;
          background-color: $white;
        }

        @include mobile {
          width: 100%;
          max-width: 500px;
        }

        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }

    &__items {
      display: flex;
      justify-content: space-between;
      margin-top: 90px;

      @include tablet2 {
        flex-wrap: wrap;
      }

      @include mobile {
        flex-wrap: wrap;
      }
    }

    &__item {
      position: relative;
      width: 270px;
      height: 380px;
      background: $blue-main;
      border-radius: 2px;
      padding: 30px;
      box-sizing: border-box;

      @include tablet2 {
        width: 330px;
        height: 430px;
        margin-bottom: 30px;
        padding: 59px;
      }

      @include mobile {
        min-width: 240px;
        width: calc(50% - 15px);
        height: 350px;
        margin-bottom: 30px;
      }

      @media (max-width: 546px) {
        width: 100%;
      }

      &:not(:last-child) {
        margin-right: 30px;

        @include tablet2 {
          margin-right: 0;
        }

        @include mobile {
          margin-right: 0;
        }
      }

      &__image {
        width: 80px;
        height: 80px;

        @include tablet1 {
          width: 50px;
          height: 50px;
        }

        @include mobile {
          width: 50px;
          height: 50px;
        }
      }

      &__header-block {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
      }

      &__header {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.02em;
        color: $white;
        margin-left: 16px;
      }

      &__option {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: $white;
        padding-left: 26px;
        background: url('../images/mark.svg') left 8% no-repeat;
        margin-bottom: 15px;
      }

      .button {
        position: absolute;
        bottom: 30px;
        left: 30px;
        right: 30px;

        @include tablet1 {
          left: 15px;
          right: 15px;
        }

        @include tablet2 {
          bottom: 59px;
          left: 59px;
          right: 59px;
        }
      }

      @include tablet1 {
        padding: 30px 15px;
      }
    }

    &__stats {
      display: flex;
      color: $white;

      &__stat {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:first-child {
          margin-right: 48px;

          @include mobile {
            margin-right: 12px;
          }
        }

        &__num {
          font-size: 65px;
          font-weight: bold;
          line-height: 67px;

          @include mobile {
            font-size: 45px;
            line-height: 54px;
          }
        }

        &__desc {
          font-size: 28px;
          line-height: 42px;
          text-transform: uppercase;

          @include mobile {
            font-size: 22px;
            line-height: 30px;
          }
        }
      }
    }
  }

  &__desc {
    background-color: $gray-background;
    padding: 64px 0;

    &__item {
      display: flex;
      gap: 50px;
      flex-direction: row-reverse;
      align-items: center;

      @include up-to-tablet2 {
        flex-direction: column;
        gap: 22px;
      }

      &__img {
        border-radius: 12px;

        @include mobile {
          width: 100%;
        }

        @include tablet1 {
          width: 100%;
        }

        &__container {
          display: flex;
          justify-content: center;
          flex: 0 0 550px;

          @include up-to-tablet2 {
            flex: unset;
          }
        }
      }

      &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include up-to-tablet2 {
          padding-top: 0;
          gap: 21px;
        }
      }

      &__title {
        font-size: 48px;
        line-height: 67px;
        font-weight: 600;

        @include mobile {
          font-size: 35px;
          line-height: 45px;
        }
      }

      &__text {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  &__programs {
    background-color: $white;
    padding: 60px 0;

    .acc-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      font-size: 48px;
      line-height: 67px;
      font-weight: 600;
      margin-bottom: 50px;

      @include mobile {
        font-size: 35px;
        line-height: 45px;
      }
    }

    &__options {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 40px;

      @include mobile {
        gap: 24px;
      }

      &__option {
        display: flex;
        flex-direction: column;
        min-width: 229px;
        flex-basis: 21%;

        @include mobile {
          flex-basis: 100%;
        }

        &__container {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &__icon {
          width: 80px;
          height: 80px;
          margin-bottom: 28px;

          @include mobile {
            margin-bottom: 16px;
          }
        }

        &__header {
          font-size: 28px;
          font-weight: 600;
          text-align: center;
        }

        &__content {
          flex-grow: 1;
          text-align: center;
          font-size: 18px;
          line-height: 25px;
          min-height: 125px;

          @include mobile {
            min-height: unset;
          }
        }

        &__button {
          height: 45px;
          width: 100%;
          background-color: $blue-main;
          color: $white;
          font-size: 18px;
          line-height: 25px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          text-decoration: none;
        }
      }
    }
  }

  &__why {
    padding-top: 70px;
    padding-bottom: 70px;

    &__header {
      font-size: 48px;
      line-height: 67px;
      font-weight: 600;
      margin-bottom: 50px;
    }

    &__card {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 25px 44px;
      flex: 41%;
      background: $blue-main;
      border-radius: 16px;
      color: $white;

      @include mobile {
        padding: 0;
        flex: 100%;
      }

      &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }

      &__icon {
        margin-right: 12px;

        @include mobile {
          margin-right: 8px;
        }
      }

      &__title {
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;

        &__container {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-bottom: 8px;

          @include mobile {
            margin-top: 22px;
          }
        }
      }

      &__content {
        font-size: 18px;
        line-height: 25px;

        @include mobile {
          padding: 0 25px 22px 25px;
        }
      }
    }
  }

  &__reviews {
    background-color: $gray-background;
    padding-top: 50px;
    padding-bottom: 64px;

    &__header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    &__navigation-container {
      display: flex;
    }

    &__navigation-btn {
      position: static;
      margin-top: 0;
      border-radius: 25px;
      background-color: $blue-main;
      width: 50px;
      height: 50px;
      color: $white;
      font-weight: bold;

      &:last-child {
        margin-left: 20px;
      }
    }

    &__header {
      font-size: 48px;
      line-height: 58px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;

      @include mobile {
        font-size: 35px;
        line-height: 45px;
      }
    }

    &__slider-container {
      width: 100%;

      .swiper-wrapper {
        height: 610px;
      }
    }

    &__card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $white;
      border: 1px solid #DAE1EC;
      border-radius: 16px;
      height: 472px;
      padding: 45px;
      font-size: 18px;
      line-height: 25px;
      //width: 290px;

      &__content {
        display: flex;
        flex-direction: column;
      }

      &__author {
        font-weight: 600;
        margin-bottom: 6px;
      }

      &__role {
        color: #2E3139;
      }

      &__comment {
        margin-top: 27px;
        color: #2E3139;
        display: -webkit-box;
        -webkit-line-clamp: 14;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__steps {
    background-color: $white;

    &__container {
      min-height: 800px;
      display: flex;
      flex-wrap: wrap;
      padding-top: 64px;
      padding-bottom: 64px;

      @include mobile {
        min-height: 0;
      }
    }

    &__img {
      height: 100%;

      @include up-to-tablet2 {
        display: none;
      }
    }

    &__accordion-container {
      padding-top: 65px;
      flex: 1;

      @include up-to-tablet2 {
        padding-top: 0;
      }
    }

    &__header {
      font-size: 48px;
      line-height: 68px;
      font-weight: 600;
      padding-left: 55px;
      margin-bottom: 32px;
      display: block;

      @include mobile {
        padding-left: 0;
        text-align: center;
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 50px;
      }
    }

    &__accordion {
      padding-left: 80px;

      @include up-to-tablet2 {
        padding-left: 25px;
      }

      &__item {
        border: 1px solid #DAE1EC;
        border-radius: 12px;
        margin-bottom: 18px;

        &__header-container {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        &__header {
          cursor: pointer;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 30px;
          height: 80px;

          &__num {
            width: 50px;
            height: 50px;
            background-color: #3A7AE0;
            border-radius: 8px;
            color: $white;
            font-size: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -25px;
            flex-shrink: 0;
          }

          &__title {
            font-size: 25px;
            font-weight: 600;
          }

          &__arrow {
            transition: all 0.2s ease 0s;
          }
        }

        &__header + &__content {
          display: none;
        }

        &__header.is-open + &__content {
          display: block;
          -webkit-animation: accordion-item-fade-in 0.6s;
          animation: accordion-item-fade-in 0.6s;
        }

        &__header.is-open &__header__arrow {
          transform: rotate(180deg);
        }

        &__content {
          font-size: 18px;
          padding-left: 45px;
          padding-right: 30px;
          padding-bottom: 15px;
        }
      }
    }
  }

  &__map {
    background-color: $gray-background;
    padding-top: 64px;

    &__container {
      background-color: $white;
      border-radius: 24px;
      border: 1px solid #DAE1EC;
      padding: 52px 60px 33px 60px;

      @include mobile {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .map-state-background:hover {
      fill: #F2B229;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mobile {
        margin-bottom: 50px;
      }

      &__title {
        font-size: 48px;
        line-height: 67px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 6px;

        @include mobile {
          font-size: 35px;
          line-height: 45px;
        }
      }

      &__desc {
        font-size: 18px;
        line-height: 25px;
        text-align: center;

        & > span {
          color: $blue-main;
          font-weight: 600;
        }
      }
    }

    &__description {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      @include tablet2 {
        flex-wrap: wrap;
      }

      @include mobile {
        flex-wrap: wrap;
      }

      &__item {
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;

        @include tablet2 {
          width: 100%;
        }

        @include mobile {
          width: 100%;
        }

        &:first-child {
          @include tablet2 {
            margin-bottom: 24px;
          }

          @include mobile {
            margin-bottom: 24px;
          }
        }

        &--blue {
          color: $blue-main;
        }

        &__states {
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
          margin-left: 15px;
        }
      }
    }

    &__image {
      margin-bottom: 50px;

      &__circles {
        display: none;
        width: 100%;

        @include tablet2 {
          display: block;
        }

        @include mobile {
          display: block;
        }
      }

      img {
        max-width: 100%;
      }
    }

    &__info {
      &__item {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        line-height: 25px;

        &:first-child {
          margin-bottom: 12px;
        }

        &__icon {
          width: 20px;
          height: 20px;
          border-radius: 2px;

          &--lend {
            background-color: $blue-main;
          }

          &--coming {
            background-color: $white;
            border: 1px solid #DAE1EC;
          }
        }
      }
    }
  }
}

// todo: extract to common.scss
@-webkit-keyframes accordion-item-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes accordion-item-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}