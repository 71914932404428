// TODO: remove once the header is replaced on all pages
.acc-header {
  height: 107px;
  background-color: $white;

  @include desktop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
  }

  & > .acc-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet1 {
      justify-content: center;
    }
  }

  &__logo {
    &__image {
      width: 131px;
      height: auto;

      @include tablet1 {
        width: 100px;
      }
    }
  }

  &__nav {
    display: flex;
    margin-left: 29px;

    @include tablet2 {
      display: none;
    }

    @include mobile {
      display: none;
    }

    &__item {
      position: relative;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      @include tablet1 {
        font-size: 14px;
        line-height: 18px;
      }

      &:not(:last-child) {
        margin-right: 36px;
      }

      &:hover {
        padding-bottom: 13px;
        margin-bottom: -15px;
        border-bottom: 2px solid #4F8AF1;
      }

      &--current {
        padding-bottom: 13px;
        margin-bottom: -15px;
        border-bottom: 2px solid #4F8AF1;
      }

      .acc-header__nav__dropdown__current-item {
        font-weight: bold;
      }

      .acc-dropdown__activator {
        color: #4B4B4B;
        text-decoration: none;
        outline: 0;        
      }

      a {
        color: #4B4B4B;
        text-decoration: none;
        outline: 0;

        &:hover {
          color: #4F8AF1;
        }
      }
    }
  }

  &__phone {
    display: flex;
    align-items: center;
    margin-left: 30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #F2B229;

    a {
      color: #F2B229;
      text-decoration: none;
    }

    @include tablet1 {
      font-size: 14px;
      line-height: 18px;
    }

    @include tablet2 {
      display: none;
    }

    @include mobile {
      display: none;
    }

    &__icon {
      width: 34px;
      height: 34px;
      margin-right: 19px;

      @include tablet1 {
        width: 9px;
        height: auto;
        margin-right: 5px;
      }
    }
  }

  &__login {
    display: flex;
    align-items: center;
    margin-left: 30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    @include tablet1 {
      font-size: 14px;
      line-height: 18px;
      margin-left: 19px;
    }

    @include tablet2 {
      display: none;
    }

    @include mobile {
      display: none;
    }

    a {
      color: #4F8AF1;
      text-decoration: none;
    }

    &__icon {
      margin-left: 5px;

      @include tablet1 {
        width: 15px;
        height: auto;
      }
    }
  }

  &__mobile {
    @include desktop {
      display: none;
    }

    @include tablet1 {
      display: none;
    }

    &__menu {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: $white;
      z-index: 1;

      &__header {
        display: flex;
        justify-content: space-between;
        padding: 28px 0 90px 0;
        width: 100%;
      }

      &__phone {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $orange;
        margin-bottom: 45px;

        a {
          color: #F2B229;
          text-decoration: none;
        }

        img {
          width: 34px;
          height: 34px;
          margin-right: 15px;
        }
      }

      &__nav {
        margin-bottom: 105px;

        &__item {
          position: relative;
          width: 100%;
          padding: 15px 0;
          text-align: center;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.02em;
          text-transform: uppercase;

          &:not(:last-child) {
            border-bottom: 1px solid #F3F3F3;
          }

          .acc-dropdown__activator,
          a {
            color: #4b4b4b;
            text-decoration: none;
          }

          .acc-dropdown__content {
            top: 60px;
            left: calc(50% - 70px);

            &--long {
              left: calc(50% - 110px);
            }
          }
        }
      }

      &__login {
        text-align: center;
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-transform: uppercase;

        a {
          color: #4F8AF1;
          text-decoration: none;
        }

        &__icon {
          vertical-align: middle;
          margin-left: 15px;
          margin-bottom: 4px;
          width: 20px;
          height: auto;
        }
      }
    }
  }
}

.acc-header-new {
  height: 96px;
  background-color: $white;
  transition: all 200ms ease 0s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;

  border-bottom: 2px solid $gray-background;
  box-shadow: 0 2px 5px 0px $gray-background;

  @include mobile {
    position: static;
    height: 62px;
  }

  @include tablet {
    position: static;
    height: 62px;
  }

  & > .acc-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    &__image {
      width: 100px;
      height: auto;

      @include tablet1 {
        width: 100px;
      }
    }
  }

  &__nav {
    display: flex;
    margin-left: 29px;

    @include tablet2 {
      display: none;
    }

    @include tablet1 {
      display: none;
    }

    @include mobile {
      display: none;
    }

    &__item {
      position: relative;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      @include tablet1 {
        font-size: 14px;
        line-height: 18px;
      }

      &:not(:last-child) {
        margin-right: 28px;
      }

      &:hover {
        padding-bottom: 13px;
        margin-bottom: -15px;
        //border-bottom: 2px solid #4F8AF1;
      }

      &--current {
        padding-bottom: 7px;
        margin-bottom: -9px;
        border-bottom: 2px solid $text;
      }

      .acc-header__nav__dropdown__current-item {
        font-weight: bold;
      }

      .acc-dropdown__activator {
        color: #4B4B4B;
        text-decoration: none;
        outline: 0;
      }

      a {
        color: $text;
        text-decoration: none;
        outline: 0;

        &:hover {
          color: #4F8AF1;
        }
      }

      .acc-dropdown__content__item {
        & > a {
          display: flex;
          flex-direction: column;
          gap: 5px;
          color: $text;

          &:hover {
            color: $blue-main;
          }
        }

        &--current {
          & > a {
            color: $blue-main;
          }
        }

        & .acc-dropdown__content__item__desc {
          text-transform: none;
          color: #2E3139;
          opacity: 0.7;
        }
      }
    }
  }

  &__phone {
    display: flex;
    align-items: center;
    margin-left: 30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    //color: $white;

    a {
      color: $orange;
      text-decoration: none;
    }

    @include tablet {
      display: none;
    }

    @include mobile {
      display: none;
    }

    &__icon {
      width: 20px;
      height: 20px;
      margin-right: 11px;

      @include tablet1 {
        width: 9px;
        height: auto;
        margin-right: 5px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-left: 30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    @include tablet2 {
      display: none;
    }

    @include tablet1 {
      display: none;
    }

    @include mobile {
      display: none;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: $white;
      background-color: $blue-main;
      text-decoration: none;

      height: 41px;
      width: 135px;
      border-radius: 5px;

      &:first-child {
        margin-right: 17px;
      }

      &--outlined {
        color: $blue-main;
        border: 1px solid $blue-main;
        background-color: transparent;
      }
    }
  }

  &__mobile {
    @include desktop {
      display: none;
    }

    &__menu {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: $white;
      z-index: 1;

      &__header {
        display: flex;
        justify-content: space-between;
        padding: 11px 0 57px 0;
        width: 100%;
      }

      &__phone {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $orange;
        margin-bottom: 45px;

        a {
          color: #F2B229;
          text-decoration: none;
        }

        img {
          width: 34px;
          height: 34px;
          margin-right: 15px;
        }
      }

      &__nav {
        margin-bottom: 60px;

        &__item {
          position: relative;
          width: 100%;
          padding: 15px 0;
          text-align: center;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.02em;
          text-transform: uppercase;

          &:not(:last-child) {
            border-bottom: 1px solid #F3F3F3;
          }

          .acc-dropdown__activator,
          a {
            color: #4b4b4b;
            text-decoration: none;
          }

          &--current {
            .acc-dropdown__activator {
              color: $blue-main;
            }
          }

          .acc-dropdown__content {
            top: 60px;
            left: calc(50% - 70px);

            &--long {
              left: calc(50% - 110px);
            }

            &--programs {
              left: calc(50% - 149px);
            }
          }
        }
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 14px;
        margin-bottom: 50px;

        a {
          color: $white;
          border-radius: 5px;
          text-transform: uppercase;
          font-size: 18px;
          line-height: 25px;
          font-weight: 600;
          width: 100%;
          max-width: 500px;
        }

        &__phone {
          background-color: #F2B229;
        }
      }
    }
  }

  &--inverse {
    background-color: $white;

    .acc-dropdown__activator {
      color: #4B4B4B;
    }

    .acc-header-new__nav__item a {
      color: #4B4B4B;
    }

    .acc-header-new__phone a {
      color: #F2B229;
    }

    .acc-header-new__buttons__button {
      border: 1px solid #4F8AF1;
      color: #4F8AF1
    }

    .acc-header-new__buttons__button--inverse {
      background-color: #4F8AF1;
      color: $white;
    }
  }

  &--transparent {
    background-color: transparent;
    border-bottom: none;
    box-shadow: none;

    .acc-dropdown__activator {
      color: $white;
    }

    .acc-header-new__nav__item a {
      color: $white;
    }

    .acc-dropdown__content__item a {
      color: $text;
    }

    .acc-header-new__phone a {
      color: $white;
    }

    .acc-header-new__buttons__button {
      color: $blue-main;
      background-color: $white;
    }

    .acc-header-new__buttons__button--outlined {
      background-color: transparent;
      border: 1px solid $white;
      color: $white;
    }
  }
}