
.acc-contact-us {
  .acc-container {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .acc-section__header__text {
    font-weight: 900;
  }  
}

.contact {
  padding-bottom: 24px;

  &__address-line {
    font-size: 22px;
  }

  &__form {
    margin: 20px 0;

    &__label {
      margin: 12px 0 2px 0;
      padding-left: 14px;
    }

    &__buttons {
      margin-top: 10px; 
    }

    &__success {
      display: none;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      padding: 20px;
      color: $orange;
    }

    &__error {
      display: none;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      padding: 20px;
      color: $red;
    }    
  }

  &__map {
    border: 0;
    
    @include mobile {
      height: 450px;
    }
  }
}

.acc-contact {
  background-color: #F2F4FA;
  padding-top: 64px;
  padding-bottom: 64px;

  @include mobile {
    padding-bottom: 0;
  }

  .acc-container {
    @include mobile {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__container {
    display: flex;
    min-height: 756px;
    background-color: $white;
    border-radius: 24px;
    border: 1px solid #DAE1EC;

    @include mobile {
      flex-direction: column;
      height: unset;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: 400px;
    gap: 30px;
    background: linear-gradient($blue-main, #2669D2);;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    padding: 60px 30px 30px 60px;

    @include mobile {
      width: unset;
      border-radius: 0;
      padding-top: 35px;
      padding-bottom: 35px;
      padding-left: 30px;
    }

    &__teams {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 50px;

      @include mobile {
        gap: 28px
      }
    }

    &__team {
      display: flex;
      flex-direction: column;
      color: $white;
      font-size: 18px;
      line-height: 25px;
      flex: 1;

      &__name {
        font-size: 28px;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 12px;
      }

      &__phone {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 4px;
        color: $white;
        text-decoration: none;

        &__icon {
          width: 20px;
          height: 20px;
        }
      }

      &__email {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $white;
        text-decoration: none;

        &__icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__social {
      display: flex;
      gap: 24px;

      @include mobile {
        margin-top: 29px;
      }

      &__icon {
        width: 45px;
        height: 45px;
      }
    }
  }

  &__form {
    padding: 27px 100px 27px 51px;
    flex: 1;

    @include mobile {
      padding-left: 33px;
      padding-right: 33px;
    }

    &__name {
      font-size: 48px;
      line-height: 67px;
      font-weight: 600;
      display: block;
      margin-bottom: 16px;

      @include mobile {
        font-size: 35px;
        line-height: 45px;
      }
    }

    &__field {
      position: relative;

      .form-error {
        position: absolute;
        bottom: 10px;
      }
    }

    &__captcha-terms {
      font-size: 14px;
      color: $text;
      margin-bottom: 24px;

      a {
        text-decoration: none;
      }
    }

    &__label {
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 25px;
    }

    &__input {
      background-color: #FBFBFB !important;
      border-color: #DAE1EC !important;
      border-radius: 8px !important;
      margin-bottom: 28px;

      &.is-danger {
        border-color: $red !important;
      }

      &[type="text"], &[type="email"] {
        height: 45px !important;
      }
    }

    textarea {
      resize: none;
      min-height: 81px;
      height: 81px;
      margin-bottom: 18px;
    }

    .button {
      text-transform: none;
      width: 218px;
      height: 57px;
      border-radius: 5px;

      @include mobile {
        width: 100%;
      }
    }
  }
}