
.acc-educational {
  background-color: $gray-background;

  &__hero {
    background: url('../images/educational/hero-bg.jpg') center bottom no-repeat;
    background-size: cover;

    //@include retina {
    //  background: url('../images/educational-videos_bg@2x.jpg') #47576d center top no-repeat;
    //  background-size: cover;
    //}

    @include mobile {
      background: url('../images/educational/hero-bg-mobile.jpg') top center no-repeat;
      background-size: cover;
    }

    &__content {
      padding-top: 94px;
      padding-bottom: 126px;

      @include mobile {
        padding-top: 51px;
        padding-bottom: 105px;
      }
    }

    .acc-caption {
      font-size: 100px;
      line-height: 100px;
      font-weight: 600;

      @include mobile {
        font-size: 45px;
        line-height: 54px;
      }
    }
  }

  &__header {
    font-weight: 600;
    font-size: 48px;
    line-height: 67px;
    color: #18181B;
    margin-bottom: 32px;

    @include mobile {
      font-size: 35px;
      line-height: 45px;
      text-align: center;
    }
  }

  &__categories {
    display: flex;
    flex-direction: column;
    gap: 46px;
    padding: 46px 0;

    @include mobile {
      padding: 52px 0;
    }
  }

  &__video {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @include mobile {
      justify-content: center;
    }

    &__item {
      padding: 0;
      max-width: 376px;

      @include mobile {
        background-color: $white;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    &__poster {
      position: relative;
      max-width: 100%;
      cursor: pointer;

      img {
        width: 100%;
        max-width: 376px;

        @include mobile {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }
      }

      &__duration {
        position: absolute;
        right: 3px;
        bottom: 6px;
        padding: 2px 3px;
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        font-size: 18px;
        letter-spacing: 0.5px;
      }
    }

    &__desc {
      @include mobile {
        padding: 0 12px 10px 12px;
      }

      &__caption {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #2E3139;
        margin: 8px 0 4px 0;
        padding-right: 16px;
        cursor: pointer;

        @include mobile {
          font-size: 22px;
          line-height: 130%;
        }
      }

      &__text {
        font-size: 18px;
        line-height: 25px;
        color: #18181B;
        opacity: 50%;
        padding-right: 16px;

        @include mobile {
          opacity: 100%;
        }
      }
    }
  }
}
