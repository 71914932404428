
.acc-referral {

  &__hero {
    background: url('../images/referral/referral-hero-bg.jpg') center bottom no-repeat;
    background-size: cover;

    &__content {
      padding: 67px 0;

      @include mobile {
        padding: 34px 0;
      }
    }

    .acc-caption {
      font-size: 80px;
      line-height: 88px;
      font-weight: 600;
      margin-bottom: 40px;
      margin-top: 0;

      @include mobile {
        font-size: 45px;
        line-height: 54px;
      }
    }

    &__buttons {
      display: flex;

      &__button {
        @include mobile {
          width: 100%;
        }
      }
    }
  }

  &__info-paid {
    background-color: $white;
    padding: 64px 0 44px 0;

    &__item {
      display: flex;
      gap: 50px;

      @include up-to-tablet2 {
        flex-direction: column;
        gap: 22px;
      }

      &__img {
        @include mobile {
          width: 100%;
        }

        @include tablet1 {
          width: 100%;
        }

        &__container {
          display: flex;
          justify-content: center;
          flex: 0 0 550px;

          @include up-to-tablet2 {
            flex: unset;
          }
        }
      }

      &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-top: 36px;

        @include up-to-tablet2 {
          padding-top: 0;
          gap: 21px;
        }
      }

      &__title {
        font-size: 52px;
        line-height: 67px;
        font-weight: 600;

        @include mobile {
          font-size: 35px;
          line-height: 45px;
        }
      }

      &__text {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  &__info-program {
    background-color: $white;
    padding: 64px 0;

    &__item {
      display: flex;
      gap: 50px;
      flex-direction: row-reverse;
      align-items: center;

      @include up-to-tablet2 {
        flex-direction: column;
        gap: 22px;
      }

      &__img {
        @include mobile {
          width: 100%;
        }

        @include tablet1 {
          width: 100%;
        }

        &__container {
          display: flex;
          justify-content: center;
          flex: 0 0 550px;

          @include up-to-tablet2 {
            flex: unset;
          }
        }
      }

      &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include up-to-tablet2 {
          padding-top: 0;
          gap: 21px;
        }
      }

      &__title {
        font-size: 52px;
        line-height: 67px;
        font-weight: 600;

        @include mobile {
          font-size: 35px;
          line-height: 45px;
        }
      }

      &__text {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  &__perks {
    background-color: $gray-background;
    padding: 64px 0;


    &__container {
      display: flex;
      gap: 50px;

      @include mobile {
        flex-direction: column;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &__img {
        width: 120px;
        height: 120px;
        margin-bottom: 28px;

        @include mobile {
          width: 80px;
          height: 80px;
          margin-bottom: 14px;
        }
      }

      &__title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 8px;

        @include mobile {
          font-size: 22px;
          line-height: 30px;
        }
      }

      &__text {
        font-size: 25px;
        line-height: 42px;

        @include mobile {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }

  &__faq {
    padding: 60px 0;
    background-color: $gray-background;

    @include mobile {
      background-color: $gray-background;
      padding-top: 40px;
      padding-bottom: 0;
    }

    &__header {
      display: block;
      font-size: 48px;
      line-height: 67px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 50px;

      @include mobile {
        text-align: center;
        font-size: 35px;
        line-height: 45px;
      }
    }

    &__accordion {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;

      @include mobile {
        gap: 0;
      }

      &__column {
        flex: 1;

        @include mobile {
          flex-basis: 100%;
        }
      }

      &__item {
        border: 1px solid #DAE1EC;
        border-radius: 12px;
        margin-bottom: 18px;
        background-color: $white;
        flex-basis: 48%;

        &__header-container {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        &__header {
          cursor: pointer;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 30px 16px 22px;
          gap: 22px;

          &__title {
            font-size: 26px;
            line-height: 36px;
            font-weight: 600;

            @include mobile {
              font-size: 22px;
              line-height: 30px;
            }
          }

          &__arrow {
            transition: all 0.2s ease 0s;
          }
        }

        &__header + &__content {
          display: none;
        }

        &__header.is-open + &__content {
          display: block;
          -webkit-animation: accordion-item-fade-in 0.6s;
          animation: accordion-item-fade-in 0.6s;
        }

        &__header.is-open &__header__arrow {
          transform: rotate(180deg);
        }

        &__content {
          font-size: 18px;
          padding-left: 22px;
          padding-right: 30px;
          padding-bottom: 15px;
        }
      }
    }
  }
}
