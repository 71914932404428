
.acc-sitemap {
  color: #6699FF;

  &__section {
    font-size: 18px;
    line-height: 25px;

    &__header {
      font-size: 32px;
      margin-bottom: 22px;
    }

    &__main {
      ul {
        padding-left: 20px;
      }
    }

    h3 {
      margin-bottom: 8px;
    }

    ul {
      margin-top: 0;
    }

    a {
      text-decoration: none;
    }
  }

  .acc-container {
    margin-top: 90px;
    margin-bottom: 90px;
  }
}
